$light-voilet: #7968ce;
$dark-voilet: #30006d;
$club-bg: #e7ecfc;
$club-background: #03b194;

.application-table {
  .rw-widget-picker {
    min-height: 20px !important;
    border-radius: 8px;
    grid-template: 1fr/1fr 1.5em;
  }
  .rw-combobox.rw-widget {
    min-width: 50%;
  }
  .rw-widget-input {
    font-size: 14px;
    width: 100%;
  }
  .rw-popup {
    font-size: 14px;
  }
  .action {
    min-width: 112px;
    max-width: 10%;
    width: 10%;
  }
  .table-td {
    td {
      vertical-align: middle !important;
    }
  }
}

.hero {
  font-size: 4px;
  background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
  background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
  min-height: 550px;
  border-bottom-left-radius: 700px;
  border-bottom-right-radius: 0px;

  h1 {
    outline: white;
    font-size: 100px;
    // background: -webkit-linear-gradient(125deg, #b8b303, #fff, rgb(0, 161, 255));
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  h2 {
    font-size: 45px;
    background: -webkit-linear-gradient(223deg, #2d00eb, #237603);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    color: #ffffff;
    text-align: center;
    width: 55%;
    padding: 18px;
    font-size: 16px;
  }
  .appply-btn {
    color: #000;
    background-color: #fff;
    border-radius: 5px;
    font-size: 18px;
    padding: 10px;
    outline: none;
  }
}
.welcome-heading {
  font-size: 40px;
}

.welcome-section {
  background-size: auto;
  text-align: center;
  border-radius: 5px;
  padding: 30px;
  .side-image {
    position: absolute;
    bottom: -40px;
  }
}

.why-section-container {
  min-height: 500px;
  .why-section {
    .welcome-text {
      color: #fff;
      text-align: center;
      padding-top: 30px;
      font-size: 40px;
    }
    border-radius: 30px;
    height: 300px;
    background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
    background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
    .card-row {
      position: relative;
      top: 50px;
      .card-why {
        display: flex;
        justify-content: center !important;
        text-align: center;
        img {
          position: absolute;
          top: -60px;
          text-align: center;
          background-color: #fff;
        }
        h6 {
          font-size: 20px;
          margin-bottom: 15px;
        }
        span {
          color: #7e7c7c;
        }
        background-color: white;
        border-radius: 15px;
        padding: 25px;
      }
    }
  }
}

.vision-section {
  h1 {
    font-size: 40px;
    margin-bottom: 70px !important;
  }
  h4 {
    line-height: 150%;
    font-size: 30px;
  }
  p {
    font-size: 16px;
  }
}

.activities-section {
  .activity-heading-text {
    display: flex;
    justify-content: center;
    h1 {
      text-align: center;
      width: 70%;
      font-size: 40px;
      margin-bottom: 70px;
    }
  }
  .activity-img {
    position: relative;
    left: 100px;
  }
  .row {
    .col-12 {
      &:nth-child(1) {
        position: relative;
        right: 120px;
      }
    }
    .col-12 {
      &:nth-child(2) {
        position: relative;
        right: 0px;
      }
    }
    .col-12 {
      &:nth-child(3) {
        position: relative;
        right: 0px;
      }
    }
    .col-12 {
      &:nth-child(4) {
        position: relative;
        right: 50px;
      }
    }
    .col-12 {
      &:nth-child(5) {
        position: relative;
        right: 200px;
      }
    }
  }

  .activities-list {
    display: flex;
    justify-content: end;
    .activities-card {
      color: white;
      max-width: 80%;
      // background-color: $light-voilet;
      background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
      background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
      border-radius: 12px;
      h6 {
        color: #000;
        font-weight: 400;
        margin-bottom: 0px;
      }
      span {
        font-size: 12px;
        color: #031f42;
      }
    }
  }
}

.club-btn {
  border: none;
  color: #000;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
  background: linear-gradient(90deg, #0072ff, 0%, #ffffff 100%);
  min-height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  :hover {
    color: #000;
  }
}
.club-btn-outline {
  color: #fff;
  position: relative;
  display: inline-block;
  border: none;
  border-radius: 6px;
  background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
  background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
  font-size: 25px;
  font-weight: 600;
  min-height: 80px;
  min-width: 45%;
  margin-top: 30px;
  margin-bottom: 30px;
  &:hover {
    background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
    background: linear-gradient(250deg, #00d47a9e 0%, #08a7c7 100%);
  }
}

.help-section {
  background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
  border-radius: 200px;
  position: relative;
  .border-module {
    background-color: white;
    border-radius: 200px;
  }

  h1 {
    color: #000 !important;
  }
  p {
    color: #7e7c7c !important;
    font-size: 18px;
  }
}

.club-application {
  background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
  background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
  .form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    h1 {
      font-size: 52px;
      color: #fff;
      margin-bottom: 0;
    }
  }
  .form-container {
    padding-bottom: 70px;
    .ant-steps-item-title {
      font-size: 14px !important;
    }
    .form-body-container {
      min-height: 300px;
    }
    .form-btn-container {
    }
  }
}
@media (max-width: 767px) {
  .application-table {
    .rw-widget-picker {
      min-height: 20px !important;
      border-radius: 8px;
      min-width: 100% !important;
    }
    .rw-combobox.rw-widget {
      min-width: 100%;
    }
    .rw-widget-input {
      font-size: 14px;
      max-width: 100%;
    }
  }
  .hero {
    font-size: 4px;
    border-bottom-left-radius: 300px;
    min-height: 500px;
    text-align: right;
    h1 {
      font-size: 45px;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 22px;
      margin-bottom: 20px;
    }
    p {
      font-size: 12px;
      padding: 0;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  .welcome-heading {
    font-size: 28px;
  }
  .club-btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .welcome-section {
    background-image: none;
    background-color: #fff;
    text-align: center;
    border-radius: 5px;
    .side-image {
      bottom: 0px;
      position: absolute;
      right: 120px;
      img {
        width: 200px !important;
        height: 200px !important;
      }
    }
  }
  .club-btn-outline {
    font-size: 18px;
    padding: 0 20px;
    border: none;
    :hover {
      box-shadow: #237603;
    }
  }
  .vision-section {
    h1 {
      font-size: 28px;
      margin-bottom: 20px !important;
    }
    h4 {
      font-size: 24px;
      margin-top: 30px;
    }
  }
  .activities-section {
    .activity-heading-text {
      display: flex;
      justify-content: center;
      h1 {
        text-align: center;
        width: 100%;
        font-size: 25px;
      }
    }
    .activity-img {
      position: relative;
      left: 0px;
      height: 250px !important;
      width: 250px !important;
    }
    .row {
      .col-12 {
        &:nth-child(1) {
          position: relative;
          right: 0px;
        }
      }
      .col-12 {
        &:nth-child(2) {
          position: relative;
          right: 0px;
        }
      }
      .col-12 {
        &:nth-child(3) {
          position: relative;
          right: 0px;
        }
      }
      .col-12 {
        &:nth-child(4) {
          position: relative;
          right: 0px;
        }
      }
      .col-12 {
        &:nth-child(5) {
          position: relative;
          right: 0px;
        }
      }
    }

    .activities-list {
      display: flex;
      justify-content: center;
      .activities-card {
        color: white;
        max-width: 100%;
        // background-color: $light-voilet;
        background: -webkit-gradient(linear, left top, right top, from(#0072ff), to(#00d4ff));
        background: linear-gradient(50deg, #00d47a9e 0%, #08a7c7 100%);
        border-radius: 12px;
        h6 {
          color: #fff;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
  .why-section-container {
    min-height: 1250px;
    .why-section {
      .welcome-text {
        color: #fff;
        text-align: center;
        font-size: 28px;
      }
      border-radius: 30px;
      .card-row {
        position: relative;
        top: 10;
        .card-why {
          display: block;
          background-color: white;
          border-radius: 15px;
          padding: 25px;
          margin-bottom: 15px;
          img {
            position: relative;
            top: 0px;
            background-color: #fff;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .help-section {
    padding: 10px;
    border-radius: 0;
    .border-module {
      background-color: transparent;
      p {
        font-size: 13px;
      }
      img {
        height: 250px;
      }
    }
  }
  .club-application {
    .form-header {
      h1 {
        font-size: 40px;
        text-align: left !important;
      }
    }
  }
}
