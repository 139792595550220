@import "_variables";

.navbar {
    background-color: $lightcolor;
    color: $greyDark;
    border-bottom: 2px solid $border-color;
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
    a,
    Link {
        color: $greyDark;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}
