$border-color: #a8a6a682;
$text-color: rgba(22, 22, 23, 0.946);
$course-studio-body-height: 84.5vh;

.course-studio-header {
  background-color: antiquewhite;
  height: 7vh;
  button {
    color: #fff;
    font-weight: 600;
    background-color: #673de6;
    padding: 5px 15px;
    border: 1px solid #673de6;
    border-radius: 5px;
    &:hover {
      background-color: transparent;
      color: #673de6;
    }
  }
}

.course-studio-side-section {
  width: 0% !important;
  left: 0%;
  transition: all 0.5s ease-in-out;
  display: block;
  padding: 0;
  margin: 0;
  background: #ffffff;
  // box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.course-studio-side-section.open {
  transition: all 0.5s ease-in-out;
  width: 30% !important;
  position: relative;
  left: 0%;
}

.course-studio-page-wrapper {
  // width: 95% !important;
  height: $course-studio-body-height;
  overflow: scroll;
  position: relative;
  width: -webkit-fill-available;
  right: 0;
  transition: all 0.5s ease-in-out;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1efef;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 3px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }
}

.course-studio-side-section.open + .course-studio-page-wrapper {
  position: relative;
  left: 0%;
  right: 0%;
  width: calc(100% - 30%) !important;
  transition: all 0.5s ease-in-out;
}

.toggle-icon {
  background: #000;
  border-radius: 50%;
  color: white;
  padding: 3px;
  position: relative;
  left: -1%;
  top: 10%;
  margin-left: 15px;
}
.disp {
  z-index: 10000;
  position: fixed;
  left: 30%;
}
.disp-2 {
  z-index: 10000;
  position: fixed;
  left: 8px;
}
// -------------------------------------

.course-studio-body-height {
  height: $course-studio-body-height;
}

.course-studio-side-nav {
  text-align: center;
  border: 1px solid $border-color;
  padding-left: 0;
  height: $course-studio-body-height;
  li {
    list-style: none;
    padding: 10px;
    i {
      font-size: 25px;
    }
  }
  :hover {
    background-color: #dbeafe;
  }
  .active {
    background-color: #dbeafe;
  }
}

// ---------Lesson Section---------------------

.lesson-sections {
  overflow: hidden !important;
  height: $course-studio-body-height !important;
  font-size: 16px;
  .lesson-header {
    h3 {
      font-size: 20px;
    }
    padding: 10px;
    border-bottom: 1px solid $border-color;
    height: 18vh;
  }

  .section-list {
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f1efef;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c3c3;
    }
  }
  .lesson-list {
    height: 59.5vh; // using vh will shrink the page relativly browser

    .section-list-item {
      font-size: 20px;
      padding: 10px 20px;
    }
  }
  .lesson-footer {
    position: relative;
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    background-color: ghostwhite;
    padding: 8px;
  }
}
.section-title {
  p {
    padding: 0 5px;
    &:hover {
      background-color: #c3c3c3 !important;
      border-radius: 5px;
      color: blue;
      text-decoration: none;
    }
  }
  input {
    padding: 0 10px;
    outline: none !important;
    background-color: aquamarine;
    border: none;
    border-radius: 5px;
  }
}
.section-open {
  p {
    background-color: #c3c3c3 !important;
    border-radius: 5px;
    color: blue;
    text-decoration: none;
  }
}
.lesson-item {
  &:hover {
    background-color: #c3c3c3 !important;
    border-radius: 5px;
    color: blue;
    text-decoration: none;
  }
}
.lesson-craft-header {
  .lesson-heading {
    font-size: 32px;
  }
}
// image - block
.image-upload-preview {
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
}

// ---------- Blocks Section - - -  - -
.block-sections {
  overflow: hidden !important;
  height: $course-studio-body-height !important;
  font-size: 16px;
  .block-header {
    h3 {
      font-size: 20px;
    }
    padding: 10px;
    border-bottom: 1px solid $border-color;
    height: 20vh;
  }
  .block-list {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 64.5vh;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f1efef;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c3c3;
    }
  }
}
.block {
  border: 2px solid #e5e7eb;
  border-radius: 5px;
  i {
    font-size: 25px;
    margin-right: 10px;
  }
  i:first-child {
    color: #e5e7eb;
  }
  &:hover {
    border-color: #3b82f6 !important;
    i:first-child {
      color: #3b82f6;
    }
  }
  .title {
    font-size: 15px;
  }
  .description {
    font-size: 10px;
    color: grey;
  }
}

.block-drop-box {
  margin-top: 30px;
  background-color: #dbeafe;
  border: 1px dotted $border-color;
  height: 60px;
  border-radius: 5px;
}

//-----------------Settings Section - - - - - - - -

.setting-section {
  overflow: hidden !important;
  // height: $course-studio-body-height !important;
  font-size: 16px;
  .setting-header {
    h3 {
      font-size: 22px;
    }
    padding: 10px;
    border-bottom: 1px solid $border-color;
    height: 20vh;
  }
  .setting-list {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 64.5vh;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f1efef;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c3c3;
    }
  }
}

// Content Block ---

.quiz-block {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .quiz-header {
    padding: 10px 20px;
    background-color: #f9fafb;
  }
  .quiz-body {
    padding: 0px 20px 10px 20px;
  }
}

//---------embed-block-----------------------

.embeds-block-header {
  background-color: #f9fafb;
  p {
    color: #6b7280;
  }
  .embeds-block-title {
    i {
      color: red;
      font-size: 25px;
      margin-right: 5px;
    }
    p {
      font-size: 20px;
      font-weight: 600;
      color: black;
    }
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 1.25rem;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

// <-----------------Code Block------------------------>
.code-container {
  position: relative;
  margin-top: 20px; /* Adjust margin as needed */
}

.copy-button {
  position: absolute;
  top: 15px;
  right: 9px;
  background: #1e1e1e;
  color: gray;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.syntax-highlighter {
  border-radius: 10px;
  padding: 20px !important;
  font-size: 16px !important; /* Adjust font size */
}

.text-area {
  font-size: 16px;
  margin: 5px;
  border-radius: 5px;
  width: 100%;
  min-height: 500px;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 20px;
  font-family: monospace;
  background-color: #2d2d2d;
  color: #f8f8f2;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

//<-----------------header------------>
.lesson-craft-header {
  position: fixed;
  width: -webkit-fill-available;
  top: 8vh;
  background-color: white; /* Ensure the header has a solid background */
  z-index: 1000; /* Ensure it is on top of other content */
  padding: 15px 0;
  border-bottom: 1px solid #ddd; /* Optional: add a border for visual separation */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for visual depth */
  border-top: 1px solid rgba(168, 166, 166, 0.5098039216);
}

.lesson-craft {
  margin-top: 100px; /* Adjust the margin-top based on the height of your header */
}

.block-drop-box {
  margin-top: 50px; /* Adjust the margin-top based on the height of your header */
}
