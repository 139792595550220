$border-color: #a8a6a682;
$text-color: rgba(22, 22, 23, 0.946);
* {
  margin: 0;
  padding: 0;
}

.course-page-wrapper {
  .course-header {
    padding-right: 20px;
    background-color: antiquewhite;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .toggle-button {
      cursor: pointer;
      width: 25px;
      height: 25px;
      margin-left: 20px;
    }
  }
  .course-list {
    padding: 40px;
  }
}

//  ------------------------Side Drawer ----------
.course-side-nav {
  width: 4% !important;
  left: 0;
  transition: all 0.5s ease-in-out;
  display: block;
  padding: 0;
  margin-top: 106px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  .side-nav-header {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    // align-items: center;
    border-bottom: 1px solid $border-color;
    padding: 15px;
    text-decoration: none;
    i {
      font-size: 22px;
    }
    p {
      display: none;
    }
  }

  span {
    display: none;
  }
  .side-nav-menu {
    ul {
      padding: 10px;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 12px 0 12px 0;
        padding: 10px;
        color: #000;
        &:hover {
          background-color: #c3c3c3;
          border-radius: 5px;
          color: #000;
          text-decoration: none;
        }
      }
      .active-tab {
        color: #673de6;
        background-color: #c3c3c3;
        span {
          color: #673de6;
          font-weight: 700;
        }
        &:hover {
          color: #673de6;
        }
      }
    }
  }

  ul {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f1efef;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c3c3;
    }
  }
}

.course-side-nav.open {
  transition: all 0.5s ease-in-out;
  width: 20% !important;

  .side-nav-header {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid $border-color;
    font-size: 18px;
    text-decoration: none;
    span {
      width: 100% !important;
      white-space: nowrap;
      display: block;
    }
  }

  .side-nav-menu {
    ul {
      width: 100%;
      padding: 10px;
      list-style-type: none;
      overflow-x: hidden;
      overflow-y: scroll;
      text-decoration: none;
      li {
        width: 100%;
        color: #000;
        align-items: left;
        justify-content: left;
        padding: 7px;
        margin: 12px 0 12px 0;
        text-decoration: none;
        display: flex;
        text-align: left;
        align-items: center;
        &:hover {
          background-color: #c3c3c3;
          color: #000;
          border-radius: 5px;
        }
        span {
          display: block;
          text-align: left;
          color: $text-color;
          font-size: 16px;
          text-decoration: none;
        }
      }
      .active-tab {
        color: #673de6;
        background-color: #ebebeb;
        span {
          color: #673de6;
          font-weight: 700;
        }
        &:hover {
          color: #673de6;
        }
      }
    }
  }
}

.course-page-wrapper {
  width: 96% !important;
  position: relative;
  left: 4%;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.course-side-nav.open + .course-page-wrapper {
  position: relative;
  left: 20%;
  right: 0;
  width: calc(100% - 20%) !important;
  transition: all 0.5s ease-in-out;
}

// Course list

.course-list {
  .course-list-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    button {
      color: #fff;
      background-color: #673de6;
      padding: 5px 15px;
      border: none;
      border-radius: 5px;
    }
  }
}

// -------------------User Courses-----------------
// Course Preview
.course-preview {
  display: flex;
  height: 95vh;
  .course-content {
    border: 1px solid #e9e9e9;
    padding: 20px 0px;
    background-color: #f8f9fa;
    height: 95vh;
    .crossBtn {
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      background-color: #fff;
      margin: 20px;
      .cross {
        width: 2em !important;
      }
    }
    .content-header {
      border-bottom: 1px solid #e9e9e9;
      height: 24vh;
      p {
        color: #808080;
      }
    }

    .content-list {
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f1efef;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 3px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c3c3c3;
      }
      height: 71vh;
      overflow-y: scroll;
      .guidlines {
        .guidlines-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: 1px solid #e9e9e9;
          h4 {
            font-weight: 700;
            margin: 0;
          }
          p {
            font-size: 25px;
            margin: 0 20px 0 10px;
          }
          Button {
            background-color: transparent;
            border: none;
            outline: none;
          }
          .up-arrow {
            cursor: pointer;
            font-size: 24px;
            color: #808080;
          }
          .down-arrow {
            cursor: pointer;
            font-size: 24px;
            color: #808080;
          }
        }
        .guidlines-list {
          ul {
            list-style: none;
            div {
              // display: flex;
              // flex-direction: column;
              // gap: 10px;

              .tickMark {
                width: 30px;
                height: 36px;
                color: #02da48;
                padding: 0 5px;
              }
              .lock {
                margin-top: 9px;
                width: 30px;
                height: 20px;
              }
              li {
                gap: 10px;
              }

              p {
                margin-left: 40px;
                display: flex;
                align-items: center;
                gap: 6px;
              }
              span {
                color: #808080;
              }
            }
          }
        }
      }
      .blocks {
        .blocks-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 15px 0;
          padding: 10px;
          // border-top:  1px solid #E9E9E9;
          border-bottom: 1px solid #e9e9e9;

          h4 {
            font-weight: 700;
            margin: 0;
          }
          p {
            font-size: 25px;
            margin: 0 20px 0 10px;
          }
          Button {
            background-color: transparent;
            border: none;
            outline: none;
            &:hover {
              background-color: transparent;
              border: none;
              outline: none;
            }
          }

          .up-arrow {
            font-size: 24px;
            color: #808080;
          }
          .down-arrow {
            font-size: 24px;
            color: #808080;
          }
        }
        .blocks-list {
          margin-left: -13px;
          ul {
            list-style: none;
            div {
              display: flex;
              flex-direction: column;
              gap: 10px;
              span {
                display: flex;
                align-items: center;
                gap: 20px;
                .tickMark {
                  width: 30px;
                  height: 30px;
                  color: #02da48;
                  padding: 0 5px;
                }
                li {
                  font-size: 20px;
                  span {
                    margin-left: 10px;
                  }
                }
              }
              p {
                margin-left: 40px;
                display: flex;
                align-items: center;
                gap: 10px;
              }
            }
          }
        }
      }
    }
  }
  .main-course-preview {
    // height: 80vh;
  }
  .preview-content {
    height: 95vh;
    display: flex;
    flex-direction: column;
    padding: 1px;
    transition: text-align 0.5s ease-in-out;
    .horizontal-bar {
      .progress-container {
        height: 5px;
        width: 100%;
        background-color: transparent;
        position: sticky;
        // top: 100px;
        left: 0;
        right: 0;
        .progress-fill {
          height: 100%;
          width: 50px;
          background-color: #673de6;
        }
      }

      .progressMainStyle {
        height: 15px;
        background-color: #673de6;
        width: 15%;
      }
    }

    .main-preview {
      overflow-y: scroll;
      height: 80vh;
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f1efef;
      }

      &::-webkit-scrollbar {
        width: 7px;
        height: 5px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c3c3c3;
      }
      .preview-detail {
        p {
          text-align: justify;
          color: #585858;
        }
        ul {
          li {
            margin-left: 40px;
            color: #585858;
          }
        }
      }
    }
    .preview-footer {
      background-color: #fefefe;
      padding: 0 100px;
      height: 15vh;
      border-top: 1px solid #f1d7ff;
      border-bottom: 1px solid #f1d7ff; 
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-progress {
          padding: 30px 0;
          display: flex;
          flex-direction: column;
  
          .footer-progress-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            p {
              font-size: 18px;
            }
          }
         
        }
        .page-switch {
          display: flex;
          justify-content: flex-end;
          gap: 20px;
          button {
            font-size: 18px;
            padding: 5px 15px;
            background-color: #fff;
            color: #000;
            border: none;
            border-radius: 10px;
            border: 1px solid #f1d7ff;
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
            &:hover {
              background-color: transparent;
              color: #673de6;
            }
          }
        }
      }
    }
  }
}

// User Course Dashboard
.new-text-color {
  color: gray;
}
.user-progress {
  p {
    margin: 0;
    padding: 0;
    // color: #585858;
  }
  .progress-detail {
    background-color: #f3efff;
    border-radius: 10px;

    .progress-badge {
      display: flex;
      align-items: center;
      gap: 30px;
      background-color: #fff;
      padding: 10px;
      border-radius: 10px;
      .badge-icon {
        width: 40px;
        height: 40px;
        border-radius: 5px;
      }
      .badge-detail {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .bg-bronze{
          background-color: #DE6B02;
        }
        .bg-silver{
          background-color: #A8A8A8;
        }
        .bg-gold{
          background-color: #C98E27;
        }
        .bg-platinum{
          background-color: #B2B2B2;
        }
    }    
  }
}
.total-time {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0px;
  .clock {
    background-color: #ffe6b9;
    padding: 6px;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    color: orange;
  }
}
.total-score {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0px;
  .checkbox {
    width: 40px;
    height: 40px;
    color: #7f7fff;
    background-color: #cfe2ff;
    padding: 6px;
    border-radius: 5px;
  }
}
  .learning {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #f3efff;
    border-radius: 10px;

    .learning-container {
      width: 100%;
      h5 {
        margin-bottom: 25px;
        .learning-progress-icon {
          // color: #673de6;
          color: #c284ee;
        }
      }
    }
    .learn-progress {
      width: 100%;
      height: 10px;
      position: relative;
      background-color: #c9b8ff;
      border-radius: 10px;
      .learn-progress-bar {
        height: 15px;
        position: absolute;
        top: -3px;
        left: 0;
        background-color: #673de6;
        border-radius: 10px;
      }
    }
  }
}

.user-course-list {
  margin-top: 30px;
  .line {
    margin-top: 20px;
    width: 100%;
    height: 2px;
    background-color: rgb(180, 180, 180);
    .line-double {
      width: 200px;
      height: 3px;
      background-color: #9129F1;
    }
  }
  .course-list {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    .list {
      margin: 0 40px 40px 0;
      width: 100%;
      border: 1px solid #acacac;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        box-shadow: 0 0 5px #acacac;
      
      }
      .icon {
        width: 100%;
        padding: 20px;
        background: linear-gradient(to right, #F5DDFD 0%, #FDDEE9 100%);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .goal-icon {
          width: 60px;
          height: 60px;
        }
      }
      .detail {
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h5 {
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: #585858;
        }
        p {
          color: #777777;
        }
      }
      .button {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 20px;
        border: 1px solid #d4c6ff;
        border-radius: 7px;
        cursor: pointer;
        button {
          background-color: transparent;
          border: none;
          h6 {
            margin: 0;
            width: max-content;
            .tickMark {
              width: 35px;
              height: 35px;
              color: #02da48;
              padding: 0 6px;
            }
          }
        }
      }
    }
  }
}

// Course Details
.course-detail-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .course-details-header {
    gap: 40px;
    .header-icon {
      background-color: #f5dcff;
      padding: 50px 50px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .icon {
        width: 40px;
        height: 40px;
      }
    }
    .header-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      p {
        color: gray;
      }
      h3 {
        margin-top: -20px;
      }
      div {
        display: flex;
        gap: 20px;
        .clock {
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
          }
        }
        .lesson {
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
          }
        }
      }
    }
  }
  .course-desciption {
    display: flex;
    flex-direction: column;
    gap: 20px;
    h3 {
      font-weight: bold;
    }
    p {
        font-size: 16px;
      span {
        font-weight: bold;
      }
    }
    .read-more {
      color: #673de6;
      font-weight: bold;
    }
  }
  .teacher-info {
    gap: 20px;
    img {
     
      max-width: 150px;
      border-radius: 50%;
    }
    p {
      margin: 0;
      color: #808080;
    }
    h3 {
      font-weight: bold;
    }
  }
}
.course-details-curriculum {
  margin-top: 40px;
  .course-details-progress {
    p {
      color: gray;
    }
    button {
      border: none;
      background-color: #01f96f;
      width: max-content;
      border-radius: 8px;
    }
  }
  .curriculum {
    margin: 20px;
    border: 1px solid #c3c3c3;
    border-radius: 20px;
    h4 {
      background-color: #f8f9fa;
      padding: 20px 40px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      font-weight: bold;
    }
    ol {
      max-height: 550px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f1efef;
      }
      .tickMark{
        width: 30px;
        height: 36px;
        color: #02da48;
        padding: 0 5px;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 5px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c3c3c3;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        span {
          display: flex;
          align-items: center;
          gap: 40px;
          .clock {
            font-size: 24px;
            color: #585858;
          }
          li {
            font-size: 16px;
            font-weight: bold;
          }
        }
        p {
          margin-left: 63px;
          display: flex;
          align-items: center;
          gap: 10px;
          color: #585858;
          font-size: 16px;
        }
      }
    }
  }
}
.custom-progress-bar {
  display: block;
  border: 1px solid #02DB4A;
  height: 51% !important;
  width: 10%;
  color: #000  !important;
  background-color: #4caf50 !important; /* Set the color of your progress bar */
}
.progress-bar{
  background-color: #02DB4A !important;
}
.btn-courses{
  background-color: #652fec;
    padding: 10px 30px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    font-weight: 500;
    float: right;
    width: 25%;
    font-size: 18px;
    transition: all 0.3s ease;
}
.complete-course-window{
  .tickMark {
    width: 30px;
    height: 36px;
    color: #02da48;
    padding: 0 5px;
  }
  .btn-courses{
    margin-right: 150px;
  }
}
//------------course student ------------
.user_list{
  .course_asign_button {
    display: inline-block;
    padding: 7px 20px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #04AA6D;
    border: none;
    border-radius: 10px;
  
  }
  
  .course_asign_button:hover {background-color: #3e8e41}
  
  .course_asign_button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  
}
.course_denie_button{
  display: inline-block;
  padding: 7px 20px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 10px;
  &:hover {
    background-color: #c3c3c3;
    border-radius: 5px;
    color: #000;
    text-decoration: none;
  }
}
// -----------------------------------
@media (max-width: 767px) {
  .nav-cross {
    display: none;
  }
}

@media (max-width: 329px) {
  .course-side-nav ul li {
    position: relative;
    left: 28px;
  }
  .nav-cross {
    display: none;
  }
}
@media (max-width: 600px) {
  .user-progress {
      margin-top: 20px;
      width: 100%;
      background-color: #f3efff;
     border-radius: 10px;
  }
  .list{
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
    .icon{
      padding: 10px !important;
    }
    .detail{
      padding: 10px !important;
    }
    .button{
      margin: 5px 8px !important;
      padding: 5px !important;
    }
  }
  .course-detail-info{
    display: block !important;
    .course-details-header{
      display: block !important;
    }
  }
  .preview-footer{
    padding: 0 33px !important;
    height: 35vh !important;
  }
}
