$gap-x : 5%;
$gap-y : 7%;
$btn-bg: #7848f4;

.padding-x{
    padding-left: $gap-x;
    padding-right: $gap-x;
}

.padding-y{
    padding-top: $gap-y;
    padding-bottom: $gap-y;
}

.webpagecontent{
    .form-group{
        display: block;
    }
}

.webpage-form{
    .btn-main {
        font-size: 16px;
      }
}

  