$dark-voilet: #30006d;
$light-blue: #0069ff;
$border-color: #b2b2b2;
$hover-color: #8247f5;

.appointment-header {
  box-shadow: 0px 20px 20px -20px rgba(0, 0, 0, 0.15);

  h4 {
    padding-left: 20px;
  }

  .appointment-header-list {
    padding-left: 60px;
  }
}

.form-header-section {
  height: 100px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;

  h1 {
    font-size: 35px;
    margin-bottom: 0;
  }
}

.manage-mentor-availability {
  .manage-mentor-header {
    padding: 15px 5px 15px 5px;
    margin: 20px 2px 30px 2px;
    background-color: #f4f2f7;
    color: #673de6;
    border-radius: 5px;
  }

  .form-body {
    border: 1px solid #ac9eb8;
    border-radius: 10px;

    h4 {
      color: rgba(26, 26, 26, 0.61);
      font-size: 16px;
      font-weight: 700;
      line-height: 46.8px;
      letter-spacing: 1.2px;
      border-bottom: 0.5px solid #ccc;
    }

    .mentor-section {
      .addmore-btn {
        border-radius: 5px;
        border: 1px solid #8247f5;
        padding: 10px;
        color: #8247f5;
        font-size: 12px;
        font-weight: 700;
        line-height: 10px;
        background-color: white;
      }

      .select-schedule-section {
        margin-top: 5px;

        .not-available-button {
          color: #122f4c;
          font-size: 14px;
          font-weight: 400;
        }

        .see-slot-button {
          cursor: pointer;
          color: #8247f5;
          font-size: 12px;
          font-weight: 700;
          line-height: 10px;
        }
      }

      .mentor-name-availability {
        cursor: pointer;
        margin-top: 10px;
        color: rgba(26, 26, 26, 0.61);
        font-size: 15px;
        font-weight: 700;
        line-height: 16.8px;
        letter-spacing: 1.2px;
      }
    }

    .time-list {
      background-color: rgba(120, 72, 244, 0.12);
      box-shadow: 0px 1px 6.1px 0px rgba(0, 0, 0, 0.25);
      margin-bottom: 0px;
      overflow-x: auto;

      li {
        cursor: pointer;
        margin-top: 12px;
        margin-left: 14px;
        margin-right: 14px;
        margin-bottom: 12px;
        padding: 11px 17px 11px 17px;
        border-radius: 4px;
        background-color: #fafafa;
        list-style: none;
        color: #122f4c;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .no-mentor-found {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
  }
}

.appointment-event-card {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 5px;
  border-top: 6px solid $border-color;

  &:hover {
    border-top-color: #8247f5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
    transition: transform 0ms linear 0s;
  }

  .appointment-card-header {
    .settings-icon {
      font-size: 20px;
    }
  }

  .appointment-card-footer {
    p {
      margin: 0;
      color: $light-blue;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-button {
      border: 1px solid #0069ff;
      border-radius: 18px;
      color: #0069ff;
      padding: 5px;
      font-size: 13px;
      font-weight: 400;
    }

    .footer-icon {
      width: 16px;
      height: 16px;
      font-size: 13px;
    }

    .footer-copy-button {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .footer-border {
    border-top: 1px solid #b2b2b2;
  }
}

.appointment-header {
  .header-create-button {
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    padding: 11px 16.66px 10px 16.67px;
    background-color: #7848f4;
    color: white;
    border: 1px solid #7848f4;
  }

  h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 39px;
  }

  .appointment-nav {
    display: flex;
    justify-content: space-between;
    padding-left: 0;

    li {
      cursor: pointer;
      margin-right: 14px;
      list-style: none;
      padding: 10px;
      background-color: white;
      color: rgba(26, 26, 26, 0.61);
    }

    .active {
      border-bottom: 5px solid #0069ff;
      color: rgba(26, 26, 26, 0.61);
    }
  }
}

.appointment-card-body {
  p {
    color: $light-blue;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }

  h5 {
    line-height: 28px;
    font-size: 18px;
    font-weight: 400;
    color: #1a1a1a;
  }
}

.appointment-detail-header {
  .shadow {
    color: rgba(0, 0, 0, 0.15);

    &.bottom {
      box-shadow: 0px 20px 20px -20px;
    }
  }

  .appointment-header-heading {
    font-size: 40px;
    font-weight: 400;
    font-weight: 400;
  }

  .detail-header-footer {
    span {
      border-radius: 10px;
      margin-right: 30px;
      padding: 11px 16.66px 10px 16.67px;
      font-size: 12px;
      font-weight: 700;
      line-height: 22px;
      color: #fff;
      background-color: #8247f5;
      border: 1px solid #8247f5;

      .copy-icon {
        font-size: 20px;
        font-weight: 800;
        color: white;
        margin-right: 6px;
      }
    }
  }
}

.appointment-detail-header-2 {
  padding-top: 50px;
  padding-bottom: 20px;

  .appointement-detailpage-border {
    border-radius: 10px;
    box-shadow: 0px 4px 18px 1px rgba(0, 0, 0, 0.25);

    .calendar-list {
      margin-right: 20px;

      .list-icon {
        font-size: 24px;
        font-weight: 800;
        margin-right: 10px;
      }

      .calendar-icon {
        font-size: 24px;
        font-weight: 800;
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;

      td,
      th {
        border: 1px solid #ddd;
        padding: 8px;
      }
    }

    .appointement-header-2 {
      display: flex;

      li {
        list-style: none;
        padding: 12px;
        cursor: pointer;
        color: rgba(26, 26, 26, 0.61);
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }

      .active {
        border-bottom: 4px solid #8247f5;
        color: #8247f5;

        .appointment-count {
          position: relative;
          top: -10px;
          border: 1px solid #8247f5;
          background-color: #8247f5;
          border-radius: 50%;
          color: white;
          padding: 3px;
          margin-left: 5px;
          font-size: 10px;
        }
      }
    }

    .header-2-list {
      display: flex;
      justify-content: space-between;
      padding-left: 0;

      li {
        cursor: pointer;
        list-style: none;
        padding: 8px 15px 8px 15px;
        border-radius: 15px;
        background-color: #93bffc;
        color: #1a1a1a;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      .active {
        color: #0069ff;
      }
    }

    .header-1-detailpage {
      .header-2-detailpage {
        .circle-icon {
          color: #0069ff;
          border-radius: 15px;
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }

        .detailpage-time {
          font-size: 14px;
          color: #1a1a1a;
          font-weight: 400;
          line-height: 24px;
        }
      }

      h6 {
        font-size: 15px;
        font-weight: 700;
        line-height: 24px;
        color: #1a1a1a;
      }

      .detailpage-detail {
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        color: rgba(26, 26, 26, 0.61);
        cursor: pointer;

        .arrow-down {
          font-size: 18px;
        }
      }
    }

    .detailpage-collapse-section {
      .cancel-button {
        border-radius: 40px;
        border: 1px solid red;
        padding: 10px 40px 10px 40px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        background-color: white;
        color: red;

        .delete-icon {
          font-size: 15px;
        }
      }

      h6 {
        font-size: 13px;
        font-weight: 700;
        line-height: 14px;
        padding-bottom: 4px;

        .pen-icon {
          stroke: rgba(26, 26, 26, 0.61);
          margin-left: 4px;
          font-size: 13px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      span {
        color: rgba(26, 26, 26, 0.61);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      .join-button {
        padding: 10px 40px 10px 40px;
        border: 1px solid #0069ff;
        background-color: #0069ff;
        border-radius: 40px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
      }

      .detailpage-note {
        .note-space {
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          color: #0069ff;
          padding-bottom: 10px;
          cursor: pointer;
        }

        .detailpage-text-field {
          border-radius: 0px 0px 8px 8px;
          border-right: 0.667px solid #ccc;
          border-bottom: 0.667px solid #ccc;
          border-left: 0.667px solid #ccc;
          border-top: none;
        }
      }
    }
  }
}

.see-more-button {
  span {
    cursor: pointer;
    color: #0069ff;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
  }
}

.clock-icon {
  color: #ac9eb8;
  font-size: 15px;
  margin-left: 8px;
}

.save-button {
  background-color: #8247f5;
  border-radius: 5px;
  padding: 6px 20px 6px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  border: 1px solid #8247f5;
}

.no-mentor-found {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 767px) {
  .form-header-section {
    h1 {
      font-size: 22px;
      color: purple;
    }
  }
}