$primary-color: #652fec;
$content-color: #8082a5;

.btn-login {
  background-color: $primary-color;
  padding: 10px 30px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
  transition: all 0.3s ease;
}
.btn-login:hover {
  background-color: #6a40d6;
}
.auth-page {
  .auth-header {
    img {
      height: 40px;
    }
  }
  > .row {
    height: calc(100vh - 50px);
    .auth-left {
      // border-right: 1px solid rgb(204, 204, 204);
      height: 100%;
      // background:linear-gradient(135deg, #CE9FFC 0%,#7367F0 100%);
      img {
        width: 70%;
      }
    }
    .btn-link{
        color: white;
        font-style: italic;
        text-decoration: underline;
    }
    .auth-right {
      //    background:linear-gradient(135deg, #CE9FFC 0%,#7367F0 100%);
      background: linear-gradient(135deg, #e8bbfc 0%, #9181eb 100%);
      height: 100%;
      position: relative;
      .auth-container {
        // margin-top: 50px;
        padding: 20px;
        color: white;
        width: 100%;
        h1 {
          // color: $primary-color;
          font-weight: bold;
        }
        form {
          max-width: 450px;
          margin: 50px auto 0;
          .form-group {
            width: 100%;
            margin-top: 10px;
            display: block;
          }
        }
      }
    }
  }
}

// Tablet View
@media (max-width: 767px) {
  .auth-page {
    .auth-right {
      .auth-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        form {
          margin-top: 20px;
        }
      }
    }
  }
}

