$section-bg: #f9f9fd;
$light-voilet: #7968ce;
$paratext: #676767;
$content-color: #8082a5;
$btn-bg: #7848f4;
$fs-heading: 40px;
$gap-x: 5%;
$gap-y: 7%;
$fs-body: 16px;

// Workshop-----------------

// Common Css------------------------
.btn-main {
  padding: 10px 30px;
  border-radius: 5px;
  background-color: $btn-bg;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: bold;
  display: block;
}

.gradient-text {
  background: linear-gradient(86.62deg, #f56c19 30.73%, #da1b60 63.23%);
  background-clip: text;
  width: fit-content;
  -webkit-text-fill-color: transparent;
}

.padding-x {
  padding-left: $gap-x;
  padding-right: $gap-x;
}

.padding-y {
  padding-top: $gap-y;
  padding-bottom: $gap-y;
}

.heading {
  font-size: $fs-heading;
  font-weight: 700;
}

@mixin shape($width, $height) {
  width: $width;
  height: $height;
  position: absolute;
  z-index: -1;
}

// Hero section --------------------------
.hero-section {
  position: relative;

  h1 {
    font-size: $fs-heading + 10px;
    max-width: 500px;
  }

  .shapes {
    .square1 {
      @include shape(30px, 30px);
      border: 5px solid rgb(255, 153, 0);
      top: 5%;
      left: 40%;
    }

    .square2 {
      @include shape(15px, 15px);
      border: 4px solid rgb(255, 0, 153);
      top: 9%;
      right: 15%;
    }

    .circle {
      @include shape(30px, 30px);
      border: 4px solid rgb(255, 213, 0);
      border-radius: 100%;
      bottom: 5px;
      left: 47%;
    }

    .triangle {
      @include shape(0px, 0px);
      border-left: 20px solid transparent;
      border-right: 30px solid transparent;
      border-top: 20px solid rgb(255, 0, 85);
      left: 5%;
      bottom: 20%;
    }
  }
}

// Workshop details section ----------------------------
.workshop-section {
  .btn-main {
    width: 100%;
  }

  .details-card {
    border-radius: 5px;
    background-color: #1d1b1c;
    color: #fff;
    padding: 20px;
  }

  .detail-block {
    @extend .details-card;
    align-items: center;

    span {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .speaker-block {
    @extend .details-card;

    p {
      font-size: 14px;
    }

    img {
      border-radius: 100%;
    }

    .participants-box {
      span {
        color: #da1b60;
      }

      img {
        width: 30px;
        height: 30px;
        position: relative;

        &:nth-child(2) {
          right: 10px;
        }

        &:nth-child(3) {
          right: 20px;
        }
      }
    }
  }

  .by {
    background: linear-gradient(86.62deg, #f56c19 30.73%, #da1b60 63.23%);
    padding: 5px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    top: 20px;
  }

  .right {
    .workshop-img {
      border-radius: 7px;
      background: linear-gradient(86.62deg, #f56c19 30.73%, #da1b60 63.23%);
      padding: 5px;

      img {
        border-radius: 5px;
      }
    }

    p {
      font-size: 14px;
      max-width: 500px;
      font-weight: 500;
    }
  }
}

// Learn Section ---------------------------------------
.learn-section {
  .btn-main {
    width: 50%;
  }

  .learn-container {
    .learn-card {
      position: relative;
      max-width: 300px;
      min-height: 300px;
      box-shadow: 0px 4px 15px 0px #0000002f;
      border-radius: 7px;

      .left,
      .right {
        position: absolute;
        z-index: -1;
        width: 50%;
        height: 100%;
      }

      .left {
        left: 0;
        background-color: #f0f0f0;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }

      .right {
        right: 0;
        background-color: #f9f9f9;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      span {
        position: absolute;
        top: 5%;
        left: 30%;
        font-size: 130px;
        font-weight: bold;
        opacity: 0.15;
      }

      .card-body {
        padding: 20px;
        padding-top: 40px;
      }
    }
  }
}


.steps-learning-section {
  color: #000;
  min-height: 500px;

  h1 {
    font-size: 50px;
    font-weight: 800;
    line-height: 64px;
  }

  p {
    font-size: 32px;
    color: #21272a;
    font-weight: 700;
  }

  .step-card {
    h6 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
    }

    span {
      color: $content-color;
      font-size: 16px;
      line-height: 2px;
    }
  }

  .explore-button {
    padding: 15px 15px 15px 15px;
    background-color: #7848f4;
    color: $section-bg;
    border-radius: 5px;
    border: none;
    font-size: 13px;
  }
}

.popular-workshop-section {

  margin-top: 50px;
  padding-top: 70px;

  .heading {
    font-size: 45px;
    font-weight: 800;
    letter-spacing: -1px;
  }

  .workshop-name {
    color: #280a64;
  }

  .explore-button {
    padding: 15px 15px 15px 15px;
    background-color: #7848f4;
    color: $section-bg;
    border-radius: 5px;
    border: none;
    font-size: 13px;
  }
}

// Learn plan section --------------------
.learn-plan-section{
  margin: $gap-y 0;
  background-color: $section-bg;
  color: $content-color;
  .date-section{
    span{
      cursor: pointer;
      &:first-child{
        color: rgb(0, 169, 241);
        border-bottom: 1px solid rgb(0, 169, 241);
      }
    }
  }

  .learn-plan-details-section{
    .learn-plan-item{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }

      h5{
        color: black;
      }
      
    }
    .short-break{
      position: relative;
      .break-line{
        width: 100%;
        height: 1px;
        background-color: rgb(114, 192, 255);
        position: absolute;
        left: 0;
        top: 50%;
      }
      .short-break-btn{
        padding: 13px 28px;
        border-radius: 30px;
        color: white;
        width: fit-content;
        background: linear-gradient(96.97deg, #F56C19 5.38%, #DA1B60 124.47%);
        position: relative;
        z-index: 1;
      }
      
    }
  }
  
}

.workshop-filter-bar {
  color: #000 !important;

  .filter-badge {
    border: 1px solid #000;
    background-color: #676767;
    color: #000;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
  }

  .sort-border {
    border: 1px solid #c5c5c5;
    border-radius: 12px;
    color: #8082a5;
  }

  .filter-border {
    border: 1px solid #c5c5c5;
    border-radius: 12px;
    color: #8082a5;
  }
}

// About Section -------------------------
.about-section {
  background-color: $section-bg;
  margin: $gap-y 0;

  p {
    max-width: 1000px;
    line-height: 40px;
    color: $paratext;
    font-weight: 600;
  }
}

.what-you-learn-workshop {
  .learn-card {
    border: 1px solid #676767;
    border-radius: 7px;
  }
}


// Testimonial Section ---------------------
.testimonial-section{
  background-color: $section-bg;
  .testimonial-card{
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #00000012;
    background-color: white;
    .star-svg {
      height: 20px;
      width: 20px;
    }
    p{
      font-size: 90%;
    }
  }
}

// FAQ Section --------------------------------
.faq-section{
  color: $paratext;
  h5{
    line-height: 160%;
  }
  .accordion{
    .accordion-item{
      border: 1px solid #F3D1BF;
    border-radius: 5px;
      .accordion-header{
        button{
          padding: 15px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: none;
          outline: none;
          background-color: transparent;
          font-size: $fs-body;
          font-weight: bold;
          text-align: left;
          span{
            color: #EF6D58;
            font-size: 24px;
          }
        }
      }

      .accordion-body{
        padding: 15px;
        padding-top: 0;
      }

    }
    
  }
}

// Media Query ------------------------
// desktop view
@media (max-width: 921px) {}

// tablet view
@media (max-width: 767px) {
  $fs-heading: 30px;
  $fs-body: 16px;
  $gap-y: 10%;

  // common css for tablet
  .padding-x {
    padding-left: $gap-x;
    padding-right: $gap-x;
  }

  .padding-y {
    padding-top: $gap-y;
    padding-bottom: $gap-y;
  }

  .heading {
    font-size: $fs-heading;
  }

  p {
    font-size: $fs-body;
  }

  // Hero section for tablet
  .hero-section {
    h1 {
      font-size: $fs-heading;
    }

    .shapes {
      .square1 {
        @include shape(20px, 20px);
        border: 4px solid rgb(255, 153, 0);
        left: 30%;
      }

      .square2 {
        @include shape(10px, 10px);
        border: 3px solid rgb(255, 0, 153);
      }

      .circle {
        @include shape(20px, 20px);
      }

      .triangle {
        @include shape(0px, 0px);
        border-left: 10px solid transparent;
        border-right: 15px solid transparent;
        border-top: 10px solid rgb(255, 0, 85);
        bottom: 10%;
      }
    }
  }

  // About Section for tablet
  .about-section {
    p {
      line-height: 30px;
    }
  }

  // Learn Section for tablet
  .learn-section {
    .learn-container {
      .learn-card {
        max-width: 250px;
      }
    }
  }

  .workshop-page-hero {
    min-height: 500px;
    align-items: center;

    h1 {
      font-size: 50px;
    }

    p {
      font-size: 16px;
      margin-top: 30px;
    }
  }

  .workshop-details-section {
    .detail-block {
      border-radius: 5px;
      background-color: #000;
      color: #fff;
      font-size: 12px;
      padding: 20px;
      align-items: center;
      display: flex;

      span {
        font-size: 16px;
        margin-left: 10px;
      }
    }

    .speaker-block {
      background-color: #000;
      border-radius: 5px;
    }
  }

  .about-workshop {
    background-color: #f9f9fd;
    color: #000;
    min-height: 500px;
    align-items: center;

    h1 {
      font-size: 45px;
    }

    p {
      font-size: 18px;
    }
  }

  // learn section for tablet
  .learn-section{
    .btn-main{
      width: 100%;
    }
  }
}

// mobile view
@media (max-width: 480px) {
  $fs-body: 14px;
  $gap-y: 15%;

  // common css for mobile
  .btn-main {
    padding: 10px 20px;
    font-size: 16px;
  }

  .padding-x {
    padding-left: $gap-x;
    padding-right: $gap-x;
  }

  .padding-y {
    padding-top: $gap-y;
    padding-bottom: $gap-y;
  }

  p {
    font-size: $fs-body;
  }
}

.card-body {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.15);
    transition: transform 0ms linear 0s;
  }

  .workshopcard-body {
    .item-days {
      color: #9672ff;
      font-size: 14px;
      font-weight: 400;
    }

    .item-category {
      color: #676767;
      background-color: #ff8fa5;
      font-size: 12px;
      font-weight: 400;
      border-radius: 6px;
      padding: 8px 20px 8px 20px;
    }

    .item-title {
      color: #081F32;
      font-size: 22px;
      font-weight: 600;
    }

    .enroll-now-button {
      background-color: #7848F4;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 400;
      border: none;
      padding: 8px 25px 8px 25px;
      border-radius: 5px;
    }

    .speaker-section-border {
      border: 1px solid #C5C5C5;
      border-left: none;
      border-right: none;

      .speaker-section {
        padding: 10px 30px 10px 10px;
        border-right: 1px solid #C5C5C5;

        .item-speaker-name {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 4px;
        }

        .item-speaker-bio {
          color: #C5C5C5;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }

    .item-title {
      color: #081F32;
      font-size: 22px;
      font-weight: 600;
    }

    .enroll-now-button {
      background-color: #7848F4;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 400;
      border: none;
      padding: 8px 25px 8px 25px;
      border-radius: 5px;
    }

    .speaker-section-border {
      border: 1px solid #C5C5C5;
      border-left: none;
      border-right: none;

      .speaker-section {
        padding: 10px 30px 10px 10px;
        border-right: 1px solid #C5C5C5;

        .item-speaker-name {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 4px;
        }

        .item-speaker-bio {
          color: #C5C5C5;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }

    .item-title {
      color: #081f32;
      font-size: 22px;
      font-weight: 600;
    }

    .enroll-now-button {
      background-color: #7848f4;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      border: none;
      padding: 8px 25px 8px 25px;
      border-radius: 5px;
    }

    .speaker-section-border {
      border: 1px solid #c5c5c5;
      border-left: none;
      border-right: none;

      .speaker-section {
        padding: 10px 30px 10px 10px;
        border-right: 1px solid #c5c5c5;

        .item-speaker-name {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 4px;
        }

        .item-speaker-bio {
          color: #c5c5c5;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }

    .speaker-section-2 {
      padding-right: 20px;
      border-left: none;

      .item-students {
        color: #c5c5c5;
        font-size: 12px;
        font-weight: 300;
      }
    }

    .rating {
      .star-svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.explore-training-body {
  h1 {
    font-size: 45px;
    font-weight: 600;
  }

  .card-body {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.15);
      transition: transform 0ms linear 0s;
    }

    .workshopcard-body {

      .item-days {
        color: #9672FF;
        font-size: 14px;
        font-weight: 400;
      }

      .item-category {
        color: #FFFFFF;
        background-color: #9672FF82;
        font-size: 10px;
        font-weight: 400;
        border-radius: 6px;
        padding: 8px 16px 8px 16px;
      }

      .item-title {
        color: #081F32;
        font-size: 18px;
        font-weight: 600;
      }

      .enroll-now-button {
        background-color: #7848F4;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
        border: none;
        padding: 6px 20px 6px 20px;
        border-radius: 5px;
      }

      .speaker-section-border {
        border: 1px solid #C5C5C5;
        border-left: none;
        border-right: none;


        .speaker-section {
          padding: 10px 30px 10px 10px;
          border-right: 1px solid #C5C5C5;
          padding-bottom: 6px;

          .item-speaker-name {
            font-size: 11px;
            font-weight: 600;
            padding-bottom: 4px;
          }

          .item-speaker-bio {
            color: #C5C5C5;
            font-size: 9px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .explore-button {
    padding: 15px 15px 15px 15px;
    background-color: #7848f4;
    color: $section-bg;
    border-radius: 5px;
    border: none;
    font-size: 13px;
  }
}

.become-mentor-body {
  background-color: #F9F9FD;
  padding: 80px 0px 30px 0px;
  margin: 110px 0px 110px 0px;

  .explore-button {
    padding: 15px 15px 15px 15px;
    background-color: #7848f4;
    color: $section-bg;
    border-radius: 5px;
    border: none;
    font-size: 13px;
  }

  .mentor-content {

    padding: 0px 30px 0px 50px;

    h1 {
      color: #391400;
      letter-spacing: -1px;
      font-size: 52px;
      font-weight: 600;
    }

    p {
      color: #391400;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.skills-success-section {
  background-color: #f5f7fa;
  padding: 30px 0px 30px 0px;
  margin-top: 80px;

  .skills-success-body {
    padding-right: 40px;

    h1 {
      font-size: 30px;
      font-weight: 700;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: #21272a;
    }
  }

  h6 {
    font-size: 16px;
    font-weight: 700;
  }

  .item-content {
    font-size: 13px;
    font-weight: 400;
    color: #21272a;
    line-height: 8.2px;
  }

  .explore-button {
    padding: 15px 20px 15px 20px;
    background-color: #7848f4;
    color: $section-bg;
    border-radius: 5px;
    border: none;
    font-size: 13px;
  }
}

// testimonial css
.workshop-testimonial-body {
  margin: 40px 60px 60px 40px;
  padding: 40px 30px 40px 30px;

  .testimonial-header {
    margin: 0px 60px 0px 70px;
  }

  h6 {
    color: #280a64;
    font-size: 18px;
    font-family: 400;
    letter-spacing: 3px;
  }

  h2 {
    font-weight: 600;
  }

  .speaker-section {

    margin: 20px 0px 70px 0px;

    .testimonial-border {
      border: 2px solid #f5f1f1;
      border-radius: 12px;

      &:hover {
        box-shadow: 1px 2px 6px 2px #e9e7e7ce;
        transition: transform 0ms linear 0s;
      }

      .item-speaker-name {
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 4px;
      }

      .item-speaker-bio {
        font-size: 13px;
        font-weight: 400;
      }

      .workshop-title {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 10px;
      }

      .item-content {
        padding-top: 5px;
        font-size: 12px;
        font-weight: 400;
        color: #21272a;
      }

      .rating {
        padding-top: 20px;

        .star-svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

// herosection css
.herosection-heading {
  position: absolute;

  h2 {
    padding: 100px 0px 20px 0px;
    font-size: 42px;
    font-weight: 700;
    line-height: 75px;
    background: -webkit-linear-gradient(87.81deg, #454DFD 15.76%, #280A64 56.8%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    color: #909090;
    font-size: 20px;
    font-weight: 400;
  }

  .lightning {
    padding-top: 30px;
    margin-top: 60px;
  }
}

.workshop-search-bar {
  position: relative;
  padding: 20px 60px 20px 60px;
  background-color: #280A64;

  .form-control {
    padding-right: 50px;
  }
}