$active-color: #3b82f6;
$fs-body: 16px;

a {
  text-decoration: none !important;
  color: #000;
}
i {
  cursor: pointer;
}

p {
  font-size: $fs-body;
}

.form-label {
  font-size: 16px;
  color: rgb(61, 79, 88);
  position: relative;
  font-weight: 500;
  line-height: 16px;
  display: inline;
  letter-spacing: 0.03em;
}

label {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.active {
  color: $active-color;
  font-weight: 600;
}

.active-with-border {
  color: $active-color;
  font-weight: 600;
  border-bottom: 2px solid $active-color;
}
.active-tab {
  background-color: #c3c3c3;
  color: #000;
  border-radius: 5px;
}

.form-input-field {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  z-index: 1;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  border-color: rgb(184, 196, 194);
}

.form-input-field-textarea {
  width: 100%;
  height: 360px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  z-index: 1;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  border-color: rgb(184, 196, 194);
}

input[type="text" i] {
  padding: 1px 7px;
}
input[type="password" i] {
  padding: 1px 7px;
}

input[type="textArea"] {
  padding: 1px 7px;
  height: 300px;
}

input[type="checkbox"] {
  padding: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

input[type="radio"] {
  padding: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

input {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
}

textarea {
  max-height: 100px;
}

.radio-emoji-input {
  /* HIDE RADIO */
  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type="radio"] + i {
    cursor: pointer;
    padding: 10px;
    background-color: #f1f3f4;
    border-radius: 5px;
    color: yellow;
    &:hover {
      background-color: #fffbda;
    }
  }

  /* CHECKED STYLES */
  [type="radio"]:checked + i {
    padding: 10px;
    background-color: #c9edf8dc;
    border-radius: 5px;
    color: #3f51b5;
  }
}

.field-error {
  font-size: 10px;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  color: red;
  letter-spacing: 0.5px;
}

.left-hr-line {
  border: solid 5px #30006d;
  width: 200px;
  text-align: left;
  align-items: left;
  justify-items: left;
  border-radius: 5px;
  margin-left: -10px;
}

.right-hr-line {
  border: solid 5px #30006d;
  width: 200px;
  text-align: right;
  align-items: right;
  justify-items: right;
  justify-content: right;
  border-radius: 5px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.upload-block {
  min-height: 100px;
  width: 100%;
  border: dashed 2px #726f77 !important;
  align-items: center;
  justify-content: center;
  i {
    align-items: center;
    font-size: 40px;
    position: relative;
  }
}

.border-preview {
  border: 1px solid blue;
  border-radius: 7px;
  // border-left: 5px solid ;
  .block-header{
    padding: 7px;
  }
  .block-content{
    padding: 16px 24px 16px 52px;
  }
}

.user-sidebar {
  width: 30vw;
  height: 100vh;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: var(--white2);
}

.profile-pic {
  margin: 100px auto 0px auto;
  height: 170px;
  width: 170px;
  border-radius: 50%;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  overflow-y: hidden;
}

.profile-pic i {
  position: absolute;
  top: 230px;
  left: 270px;
  cursor: pointer;

  color: var(--white);
}

.camera-icon {
  font-size: 18px;
  position: absolute;
  bottom: 0%;
  left: 55%;
  background-color: #ffffff;
  height: 30px;
  width: 30px;
  box-shadow: 1px 1px #c3c3c3;
  border-radius: 50%;
  display: inline-block;
  color: #000;
  padding: 3px;
  align-items: center;
}

.profile-blank-frame {
  border: 1px solid;
  border-radius: 50%;
  height: 144px;
  width: 144px;
  padding: 10px;
}

.card-action {
  position: absolute;
  right: 0%;
}

.custom-card {
  .card-action {
    display: none;
  }
  &:hover {
    .card-action {
      display: block;
    }
  }
}

.page-not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  img{
    max-width: 500px;
  }
}