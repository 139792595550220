@import "_variables";
.quiz-board {
  min-height: 100vh;
  background-color: $background-color;
  margin-top: 57px;
  text-align: left;
  .quiz-main-section {
    .task-div {
      border: 1px solid $border-color;
      background-color: white;
      .img-div {
        background-color: rgba(191, 191, 214, 0.5);
        padding: 0px 3px;
        img {
          object-fit: contain;
          width: 80%;
          height: 60%;
        }
      }
      a {
        color: $greyDark;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .quiz-test-details-container {
    background: #f8f9fa; // Light background for sections
    border-radius: 8px;
    // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    .section-title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333;
    }

    .test-section {
      background: #fff;
      border-radius: 8px;
      border: 1px solid #ddd;
      overflow: hidden;

      .test-header {
        background: #e9ecef;
        cursor: pointer;
        padding: 10px 15px;
        border-bottom: 1px solid #ddd;
        transition: background 0.3s ease;

        &:hover {
          background: #dfe3e7;
        }

        .test-title {
          font-size: 1rem;
          color: #333;
          margin: 0;
          font-weight: 600;
        }

        .icon {
          font-size: 1.25rem;
          color: #007bff;
        }
      }

      .toggle-container {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.5s ease, opacity 0.5s ease;

        &.show {
          max-height: 500px; // Adjust as necessary for your largest dynamic content
          opacity: 1;
        }

        &.hide {
          max-height: 0;
          opacity: 0;
        }
      }

      .empty-message {
        font-size: 1rem;
        color: #666;
        background: #f8f9fa;
        border-radius: 4px;
      }
    }
  }

  .task-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .task-card {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      padding: 15px;
      margin: 15px;
      cursor: pointer;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }

      .card-content {
        display: flex;
        align-items: center;

        .card-image {
          flex: 1;
          text-align: center;
          img {
            max-width: 80px;
            height: auto;
          }
        }

        .card-text {
          flex: 2;
          padding-left: 15px;

          .card-title {
            font-size: 1rem;
            font-weight: 600;
            color: #007bff;
            margin-bottom: 5px;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          .card-description {
            font-size: 0.875rem;
            color: #555;
          }
        }
      }
    }
  }
}


/* Extra Small Devices, Phones */

@media (max-width: 576px) {
  .task-div-section {
    flex-direction: column;
    row-gap: 20px;
  }
}
