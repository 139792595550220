@import "_variables";

.quiz-list {
  background-color: #f8f9fa;
  min-height: 100vh;

  .quiz-list-body {
    padding: 20px;
    background: #ffffff;
    border-left: 1px solid #ddd;
  }

  .quiz-list-header {
    padding-bottom: 15px;
    border-bottom: 2px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-container {
    position: relative;
    width: 100%;

    input {
      width: 100%;
      padding: 10px 35px 10px 15px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 1rem;
    }

    .search-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #666;
      font-size: 1rem;
    }
  }

  .create-btn {
    background: #6666d1;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      background: #5757c1;
    }
  }
}

//quiz-list side-navbar
.side-navbar {
  border-top: none;
  padding: 20px;
  font-family: "Arial", sans-serif;

  .section-header {
    background: #6666d1;
    color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 20px;

    h5 {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
    }
  }

  .status-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 10px 0;
      text-decoration: none;
      font-size: 1rem;
      color: #333;
      display: flex;
      align-items: center;

      .icon {
        font-size: 1.2rem;
        margin-right: 10px;
        color: #00000088;
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    background: #ddd;
    margin: 20px 0;
  }

  .filter-section {
    .dropdown-section {
      margin: 20px 0;

      label {
        font-size: 0.9rem;
        color: #444444;
        margin-bottom: 5px;
        display: block;
        font-size: 1rem;
        font-weight: bold;
        color: #333;
      }
    }

    .filter-title {
      font-size: 1rem;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
      color: #333;
    }
  }
}

//  responsive
@media (max-width: 600px) {
  .SideNavbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .headerMenu {
    display: none;
  }
}
@media (min-width: 600px) {
  .menuIcon {
    display: none;
  }
}
@media (max-width: 600px) {
  .sidebar {
    margin-top: none !important;
    position: fixed;
    a,
    Link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: $greyDark;
    }
    ul {
      list-style: circle;
      li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        color: #555;
      }
    }

    .line-break {
      border: none;
      height: 0.5px;
      margin-bottom: 1rem;
      background-color: rgba(0, 0, 255, 0.5);
    }

    select,
    input[type="checkbox"] {
      margin-bottom: 0.5rem;
      width: 100%;
    }

    select {
      padding: 0.375rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      background-color: #fff;
    }

    input[type="checkbox"] {
      width: auto;
      margin-right: 0.5rem;
    }
  }
}

// quizList Dashboard

@media (max-width: 600px) {
  $font-size: small;
  $f-s: 15px;
  .quiz-list {
    .quiz-list-body {
      min-height: 100vh;
      margin-top: 0px !important;
      background-color: $background-color;
      .quiz-list-body-child-A {
        // display:flex;
        align-items: center !important;
        justify-content: center !important;
      }
      input {
        padding: 3px;
        width: 70%;
        width: 85%;
        border: 1px solid $border-color;
        border-radius: 2px 2px 2px 2px;
        // font-size:  $f-s!important;
      }
      .search-icon {
        background-color: $button-color;
        padding: 4px 10px;
        color: $lightcolor;
        border: 1px solid $border-color;
        border-radius: 3px 3px 3px 3px;
        // font-size:  $f-s!important;
        font-size: 18px;
      }
      button {
        background-color: $button-color;
        // padding: 4px 2px;
        color: $lightcolor;
        border: 1px solid $border-color;
        border-radius: 6px;
        font-size: $f-s !important;
      }
      a,
      Link {
        text-decoration: none;
        h6 {
          color: $greyDark;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .quiz-list-body-child {
        display: flex !important;
        flex-direction: column-reverse;
        row-gap: 10px;
        .bty {
          text-align: right;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .test-card {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .quiz-list {
    .quiz-list-body {
      padding: 15px;
    }

    .search-container {
      input {
        padding: 8px 35px 8px 12px;
      }
    }

    .create-btn {
      font-size: 14px;
      padding: 8px 15px;
      float: right;
      width: fit-content !important;
    }
  }
}

@media (max-width: 768px) {
  .quiz-list {
    .quiz-list-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .quiz-list-search {
      width: 100%;
    }

    .create-btn {
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 576px) {
  .quiz-list {
    .quiz-list-header {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .search-container {
      input {
        font-size: 14px;
        padding: 8px 35px 8px 12px;
      }
    }

    .create-btn {
      width: 100%;
      padding: 10px;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .ant-drawer-body {
    padding: 0 10px !important;
    .side-navbar {
      padding: 0px;
      border: none;

      .section-header {
        padding: 8px 12px;
      }

      .status-list li {
        font-size: 0.9rem;

        .icon {
          font-size: 1rem;
          margin-right: 8px;
        }
      }

      .filter-section {
        .filter-title {
          font-size: 0.9rem;
          margin-top: 15px;
          margin-bottom: 8px;
        }

        .dropdown-section label {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .ant-drawer-body {
    padding: 0 10px !important;
    .side-navbar {
      padding: 0px;
      border: none;

      .section-header {
        padding: 6px 10px;
      }

      .status-list li {
        font-size: 0.85rem;

        .icon {
          font-size: 0.9rem;
          margin-right: 6px;
        }
      }

      .filter-section {
        .filter-title {
          font-size: 0.85rem;
        }

        .dropdown-section label {
          font-size: 0.85rem;
        }
      }
    }
  }
}
