@import "_variables";
@import "candidates_table.scss";

.quiz-test-header {
  background-color: #fff; /* Light background for header */
  padding: 10px 15px;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  .col-2 {
    .bi-arrow-left {
      color: #673de6;
    }
  }

  .col-lg-8 {
    .d-flex {
      justify-content: space-between;
    }
  }

  .btn-invite {
    background-color: #673de6;
    color: white;
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: #5b31b1; /* Darker shade of the primary color */
    }
  }

  a {
    color: #673de6;
    text-decoration: none;

    &:hover {
      color: #5b31b1;
    }

    .bi {
      margin-right: 5px;
    }
  }
}

.quiz-test-sidenav {
  padding: 20px;
  font-family: "Arial", sans-serif;

  .section {
    margin-bottom: 20px;
    h5 {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
      padding-left: 10px;
      border-left: 3px solid #6767d6;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0 10px;

      li {
        font-size: 14px;
        color: #555;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background: rgba(103, 103, 214, 0.1);
          color: #333;
        }
      }

      .active-tab {
        background-color: rgba(103, 103, 214, 0.1);
        border-left: 5px solid #6767d6;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

.quiz-test-body {
  background-color: $background-color;
}
.testTaken-section {
  .header-testtaken {
    a {
      text-decoration: none;
    }
    #accessSelect {
      border: none;
      background-color: $background-color;
      color: blue;
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }
}
.invited-section,
.candidate-feedback-section {
  text-align: left;
  a {
    text-decoration: none;
  }
  .invited-table {
    tr {
      border: 2px solid $border-color;
    }
    tr td {
      background-color: $lightcolor;
      font-size: 14px;
    }
  }
  button {
    background-color: $sidebarlinkcolor;
    color: $lightcolor;
    border: 1px solid $sidebarlinkcolor;
    border-radius: 2px;
    font-size: 16px;
  }
}
.test-analytics-section {
  min-height: 100vh;
  text-align: left;

  .reliability-section,
  .candidate-funnel,
  .metric-performnace,
  .candidate-score {
    background-color: $lightcolor;
    border-radius: 5px;
    border: 2px solid $border-color;
    p,
    span {
      color: rgb(114, 112, 112);
      padding: 2px;
      margin: 0px;
    }
    .rise {
      color: rgb(90, 221, 90);
    }
    .drop {
      color: red;
    }
    .detail {
      color: $greyDark;
    }
    .reliability-score {
      border: 2px solid $border-color;
      border-radius: 20px;
      max-width: 22%;
      padding: 2px 7px;
      .score {
        color: rgb(50, 199, 50);
        font-weight: 500;
      }
    }
  }
}
.question-analytics {
  h4 {
    color: rgb(75, 75, 224);
  }
  .line-v {
    display: block;
    height: 3px;
    background-color: $border-color;
  }
  // .QuestionAnalyticsRowCard{
  //   overflow: hidden;
  // }
  .question-analysis-card {
    background-color: $lightcolor;
    font-size: 15px;
    border: 1px solid $border-color;
    border-radius: 5px;
    .ques {
      font-weight: 500;
    }
    .line-h {
      border: 1px solid $border-color;
      display: flex;
    }
    .chart-block {
      border-left: 2px solid $border-color;
    }
  }
}
.quiz-test-overview {
  margin: auto;
  text-align: left;
  a {
    text-decoration: none;
  }
  .side-nav-section {
    background-color: $lightcolor;
  }
  .advanced-setting {
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 5px;
    .bi-check-circle {
      color: green;
    }
  }
  .setting-section,
  .other-section,
  .test-description,
  .test-instructions,
  .faq-section {
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 5px;
    p {
      color: grey;
    }
  }
  .other-section {
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 5px;
    p {
      color: $greyDark;
      margin: 0px;
    }
    input {
      border: none;
      padding: 0px;
      color: grey;
    }
  }
  .head-test-instruction {
    button {
      text-decoration: none;
      border: none;
    }
  }
  .select-dashboard {
    background-color: $background-color;
    border: none;
    justify-content: center;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
  .info-section {
    background-color: rgba(234, 243, 254, 255);
    border-left: 3px solid rgba(71, 142, 233, 255);
  }
}

.email-tab-section {
  text-align: left;
  .select-template {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  select {
    width: 20%;
    &:hover {
      box-shadow: none;
    }
  }
  .form-section {
    border: 1px solid $border-color;
    background-color: $lightcolor;
    border-radius: 6px;
    font-size: 18px;
  }
  .note-section {
    font-size: 15px;
  }
}

// quiz_test_dashboard Media Query
// drawer code start

.otherdetailsform {
  .form-control {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
    transition: border-color 0.3s;
  }

  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }

  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }

  input {
    padding: 0 10px !important;
  }
}

.custom-modal {
  .custom-modal-header {
    background-color: #673de6;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 15px;
    font-size: 1.2rem;
    border-bottom: 2px solid #ddd;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    position: relative;
    padding-right: 2rem !important;
    .custom-close-button {
      position: absolute;
      right: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
      color: #FFF;
      background: none;
      border: none;
      cursor: pointer;
      opacity: 1 !important;
    }

    .close span {
      display: inline-block;
    }
  }

  .custom-modal-body {
    padding: 20px;
    font-size: 1rem;
    color: #333;

    .modal-message {
      font-size: 1rem;
      color: #555;
      text-align: center;
      background: #f8f9fa;
      padding: 15px;
      border-radius: 8px;
      font-weight: 500;
    }
  }

  .custom-modal-footer {
    display: flex;
    justify-content: center;
    padding: 15px;
    border-top: 1px solid #ddd;

    .submit-btn {
      background: #28a745;
      color: #fff;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      margin-right: 10px;

      &:hover {
        background: #218838;
      }
    }

    .cancel-btn {
      background: #dc3545;
      color: #fff;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;

      &:hover {
        background: #c82333;
      }
    }
  }
}

@media (min-width: 600px) {
  .QuizTestSideNavbarDrawer {
    display: none;
  }
}
@media (max-width: 600px) {
  .quiztestsideNAvbar {
    display: none;
  }
}
@media (min-width: 600px) {
  .side_navbar-drawer {
    display: none;
  }
}

// Drawer code end

@media (max-width: 600px) {
  .Header_Menu {
    display: none;
    text-align: center;
  }
}
@media (min-width: 600px) {
  .Menu_Icon {
    display: none;
  }
}
// QuizTestHEader
$font-size: 13px;

@media (max-width: 600px) {
  .quiz-test-header {
    display: flex;
    flex-direction: row;
    font-size: $font-size;

    .quiz-test-header-child-A {
      flex-direction: column;
      font-size: $font-size;

      button {
        text-align: center;
        font-size: $font-size;
        min-width: fit-content;
        padding: 2px 0px;
      }
    }
  }
}
// setting section code
// other setting section

@media (max-width: 600px) {
  .OtherDetailsSection {
    padding-top: 3.5vh !important;
  }
}

@media (max-width: 600px) {
  .email-tab-section {
    font-size: $font-size;
    .form-section {
      display: flex;
      font-size: $font-size;
      flex-direction: column;
      .note-section {
        padding-top: 0px !important;
        justify-content: space-evenly;
      }
    }
    select {
      width: 50%;
      &:hover {
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .reliability-score {
    min-width: 74%;
    //  font-size: $font-size;
  }
  .metric-performnace {
    // font-size: $font-size;
  }
  .analytics-header {
    // font-size: $font-size;
  }
  .question-card-response {
    display: flex;
    flex-direction: column;
  }

  .candidate-feedback {
    display: flex;
    flex-direction: column;
    button {
      width: 60vw;
    }
  }
}
@media (max-width: 600px) {
  .header-testtaken {
    .Header-Test-Taken-Child {
      display: flex;
      flex-direction: column;
    }
  }
}
@media (max-width: 600px) {
  .other-section {
  }
}
