$dark-more-bg-color: #121212;
$heading-text: #353833;
$base-font-color: #726f77;

$event_light_bg: #f9fbfc;
$light-voilet: #7968ce;
$purple: #6866d0;
$v-light-voilet: #d7d0fff1;
$light-grey: #cdd0cb;
$dark-grey: #a6a9b6;
$dark-yellow: #ffb830;
$dark-red: #ff2442;
$dark-blue: #1d91fd;
$dark-green: #04a93b;
$dark-pink: #ec0068;
$light-green: #d6ffce;
$light-red: #fde5e7;
$light-blue: #e2f7f8;
$light-yellow: #fbffc5;
$medium-yellow: #f5f852;
$medium-green: #91fa70;
$medium-red: #fb7367;
$orange: #ff5f15;
$light-orange: #fce8df;
$font-fam: "Inter", sans-serif;

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.z-n1 {
  z-index: -1;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 1;
}

.dark-bg-color {
  background-color: #ffffff;
  position: fixed !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.upper-header {
  min-height: 300px;
  background-color: #30006d;

  h1 {
    color: #ffffff !important;
  }
}

.body-section {
  position: relative;
  top: -161px;
}

.header-banner {
  min-height: 400px;
  background-image: url("../images/svg/dayscode-header.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  h1 {
    font-size: 45px;
    color: $heading-text;
    letter-spacing: 0.9px;
  }
}

.enroll-btn {
  border: solid 1px #673de6;
  border-radius: 50px;
  min-width: 40%;

  span {
    color: #673de6;
    font-size: 16px;
    font-weight: 300;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    transition: ease-in-out 1ms;
    border: solid 1px #673de6;
    background-color: $light-voilet;

    span {
      transition: ease-in-out 1ms;
      color: #ffffff;
      font-size: 17px;
      font-weight: 500;
    }
  }
}

.enroll-small-btn {
  border: solid 1px #673de6;
  border-radius: 50px;
  min-width: 40%;

  small {
    color: #673de6;
    font-size: 12px;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    transition: ease-in-out 1ms;
    border: solid 1px #673de6;
    background-color: $light-voilet;

    small {
      transition: ease-in-out 1ms;
      color: #ffffff;
      font-size: 12px;
    }
  }
}

.enroll-small-white-btn {
  border: solid 2px #ffffff;
  border-radius: 50px;

  small {
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 12px;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    transition: ease-in-out 1ms;
    border: solid 2px #673de6;
    background-color: #ffffff;

    small {
      color: #000000;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
}

.about-code-section {
  h1 {
    font-size: 36px;
    color: $heading-text;
    letter-spacing: 0.9px;
  }

  span {
    font-size: 15px;
  }

  h6 {
    color: $heading-text;
    font-size: 20px;
    font-weight: 400 !important;
  }

  .icon-card {
    padding: 15px;
    border: solid 1px $light-grey;
    border-radius: 5px;

    i {
      font-size: 30px;
      color: #ffffff;
    }

    p {
      font-size: 14px;
      color: #ffffff;
    }
  }

  ul {
    li {
      list-style: none;

      span {
        font-size: 16px;
      }
    }
  }

  font-weight: 300;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 20px 0 5px 25px;

      &::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        margin-top: 2px;
        margin-left: -25px;
        display: table;
        float: left;
        color: $light-voilet;
        font-weight: 500;
      }
    }
  }
}

.learning-objective {
  background-color: $event_light_bg;

  ul {
    li {
      list-style: none;

      span {
        font-size: 16px;
      }
    }
  }

  font-weight: 300;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 20px 0 5px 25px;

      &::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        margin-top: 2px;
        margin-left: -25px;
        display: table;
        float: left;
        color: $light-voilet;
        font-weight: 500;
      }
    }
  }
}

.why-join-us {
  h1 {
    font-size: 36px;
    color: $heading-text;
    letter-spacing: 0.9px;
  }

  h6 {
    color: $heading-text;
    line-height: 20px;
    font-size: 20px;
    margin-top: 15px;
    letter-spacing: 1px;
  }
}

// Code Dashboard
.code-dashboard {
  background: #ffffff;
  min-height: 200px;
  border-radius: 7px;

  h1 {
    font-size: 36px;
    color: $heading-text;
    letter-spacing: 0.9px;
  }

  .qoute {
    font-size: 15px;
    color: $light-voilet;

    h6 {
      color: $base-font-color;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .quote-icon {
    position: relative;
    font-size: 25px;
  }
}

.dashboard-heading {
  font-size: 20px;
  letter-spacing: 1px;
  color: $heading-text;
  padding-bottom: 15px;
  border-bottom: solid 1px #dee2e6;
}

.code-dashboard-notification {
  background: #ffffff;
  min-height: 200px;
  border-radius: 7px;

  h1 {
    font-size: 36px;
    color: $heading-text;
    letter-spacing: 0.9px;
  }
}

.large-text {
  font-size: 70px;
  color: $heading-text;
  font-weight: 100;
}

.lesson-dashboard-progress {
  h1 {
    font-size: 45px;
  }
}

.problem-banner-box {
  // background-color: $light-voilet;
  align-items: center;
  border-radius: 7px;
  height: 100%;
  display: grid;
  text-decoration: none !important;

  i {
    font-size: 25px;
    padding: 22px;
    color: $light-voilet;
  }

  h2 {
    color: $light-voilet;
    text-align: center;
    font-size: 20px;

    i {
      font-size: 20px;
      padding: 10px;
    }
  }

  &:hover {
    border: solid 2px $light-voilet !important;
    border-radius: 7px;
    background-color: #ffffff;

    a {
      text-decoration: none !important;
    }

    h2 {
      text-decoration: none !important;
      color: $light-voilet !important;
    }

    i {
      color: $light-voilet;
    }
  }
}

.solution-nav {
  cursor: pointer;
  box-shadow: 1px 1px 5px 1px rgba(59, 73, 94, 0.2);

  .solutiob-col {
    &:hover {
      background-color: #dfe7ff;
    }
  }
}

.nav-active {
  color: $heading-text;
  font-weight: 600;
  border-bottom: solid 4px $light-voilet;
}

.problems-side-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;

  .side-problems-card {
    border-radius: 7px;
    box-shadow: 1px 1px 5px 1px rgba(59, 73, 94, 0.2);

    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 4px 2px rgba(59, 73, 94, 0.2);
      background-color: #dfe7ff;
    }

    .side-problem-text {
      font-weight: 500;
      font-size: 12px;
      color: #121212;
    }

    .difficulty-level-text {
      font-size: 12px;
      color: #888888;
      font-style: oblique;
    }
  }

  .side-problems-card-content-collapse {
    padding: 10px !important;
    border-radius: 7px;
    border-top: none;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        font-size: 14px;
        padding: 5px 0;
      }
    }
  }
}

.side-section-drawer {
  // z-index: 200;
  width: 5% !important;
  left: 0;
  transition: 0.5s all ease-out;
  display: block;

  .problems-side-section {
    height: 90vh;
  }

  .solution-nav {
    span,
    small {
      display: none;
    }
  }

  .side-problems-card {
    text-align: center !important;

    .tooltip-inner {
      display: none;
    }

    p,
    .side-problem-text,
    .day-text,
    .carousel-icon,
    .fa-lock-open-alt {
      display: none;
    }

    .pr-4 {
      padding: 0;
    }

    .progress-dot {
      display: inline-block;
      transition: 0.5s all ease-in-out;
    }

    .inner-box {
      display: inline-block;
      transition: 0.5s all ease-in-out;
    }

    .day-text-close-drawer,
    i,
    span {
      text-align: center;
      justify-content: center;
    }
  }
}

.side-section-drawer.open {
  transition: 0.5s all ease-in-out;
  // height: 400px;
  // position: fixed;
  width: 20% !important;

  .problems-side-section,
  .solution-nav {
    span,
    .day-text,
    small {
      display: block;
    }
  }

  .problems-side-section {
    height: 70vh;
  }

  .text-section {
    padding-right: 20px;
  }

  .side-problems-card {
    justify-content: space-between;
    padding: 10px !important;

    p {
      display: block;
    }

    .carousel-icon {
      display: inline;
    }

    .inner-box {
      display: flex;
    }

    small,
    span,
    .difficulty-level-text {
      text-align: left;
    }

    .day-text-close-drawer {
      display: none;
    }
  }
}

.page-content-wrapper {
  width: calc(100% - 5%) !important;
  position: relative;
  left: 0%;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.side-section-drawer.open + .page-content-wrapper {
  position: relative;
  left: 0%;
  right: 0;
  width: calc(100% - 20%) !important;
  transition: all 0.5s ease-in-out;
}

.problems-side-section,
.problem-section,
.solution-section,
.practice-section,
.submissions-list-section,
.leaderboard-section,
.notification-section,
.lesson-section,
.challenge-section,
.daily-task-card-section {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1efef;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }
}

.leaderboard-section,
.notification-section {
  overflow-y: scroll;
  height: 400px;
}

.submissions-list-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}

.dashboard-btn {
  color: $light-voilet;
}

.selected {
  background-color: #def2fd;
}

.problem-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  min-height: 100%;

  p {
    color: $heading-text;
  }

  ul {
    li {
      color: $base-font-color;
    }
  }
}

.lesson-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90vh;
  min-height: 100%;
}

.challenge-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 75vh;
  min-height: 100%;
}

.solution-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
  min-height: 100%;
}

.practice-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
  min-height: 100%;
}

.daily-task-card-section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
  min-height: 100%;
}

.days-heading {
  font-size: 15px;
  color: $heading-text;
  letter-spacing: 1px;
}

.problem-statment {
  font-size: 45px;
  font-family: "Dosis", sans-serif !important;
}

.solution-header {
  h1 {
    font-size: 36px;
    color: $heading-text;
  }

  h2 {
    font-size: 20px;
    color: $heading-text;
  }

  border: 1px solid $dark-grey;
  border-radius: 5px;
}

h1 {
  font-size: 36px;
  color: $heading-text;
  letter-spacing: 1px;
}

.solution-counter {
  display: grid;
  justify-content: center !important;
  align-content: center !important;

  h1 {
    font-size: 36px;
    color: $base-font-color;
  }
}

.wait-timer-icon {
  font-size: 100px;
  color: $base-font-color;
  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 15s;
  animation-timing-function: linear;
  position: relative;
  padding: 20px;
  justify-content: center;
  display: flex;
}

.problem-wait {
  i {
    font-size: 100px;
    color: $base-font-color;
    padding: 20px;
  }

  h1 {
    font-size: 20px;
    color: $base-font-color;
  }
}

.code-setting-side-nav {
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: none;
  font-family: $font-fam !important;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ::-moz-scrollbar {
    width: 0;
    height: 0;
  }
  cursor: pointer;
  ul {
    text-decoration: none !important;
    .side-nav-option {
      text-decoration: none !important;
      list-style: none;
      margin: 0 15px 10px 15px !important;
      padding: 2px 5px 4px 5px;
      span {
        color: #626c70;
        font-size: 14px !important;
        font-weight: 400;
      }
      .close-drawer-icon {
        font-size: 20px !important;
      }
      .open-drawer-item {
        // margin-left: 10px;
      }
      &:hover {
        background-color: #7948f442;
        border-radius: 5px;
      }
    }
    .option {
      text-decoration: none;
      list-style: none;
      li {
        text-decoration: none !important;
        color: #626c70;
        padding: 3px 0 3px 10px !important;
        margin: 3px 15px 10px 30px !important;
        font-size: 14px !important;
        padding-bottom: 0;
        &:hover {
          background-color: #7948f442;
          border-radius: 5px;
        }
      }
    }
    .no-hover {
      background-color: transparent !important;
      &:hover {
        background-color: transparent !important;
        cursor: pointer;
      }
    }
    .no-option {
      background-color: transparent !important;
    }
    .active {
      background-color: #7848f4 !important;
      color: white !important;
      border-radius: 5px;
      // padding: 5px !important;
    }
    .active-drop {
      background-color: #7848f4 !important;
      color: white !important;
      border-radius: 5px;
      padding-left: 2px !important;
      padding-right: 2px !important;
      padding-top: 1px !important;
      padding-bottom: 2px !important;
      // padding: 5px 0 5px 0 !important;
    }
    .active-option {
      background-color: #7948f442 !important;
      border-radius: 5px;
    }
  }
}
// .font-color {
//   color: #626c70;
// }
.dropdown-arrow {
  position: absolute;
  top: 5px;
  right: 10px;
}

.solution-code-textarea {
  max-height: 100%;
  width: 100%;
  height: 300px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  z-index: 1;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  border-color: rgb(184, 196, 194);
}

.mentor-review-card {
  width: 100%;
  min-height: 130px;
  background-color: $light-yellow;
  border-left: 8px solid $dark-yellow;

  i {
    font-size: 35px;
  }

  h1 {
    font-size: 20px;
  }
}

.yellow-card {
  background-color: $light-yellow;
  border-left: 8px solid $dark-yellow;
}

.green-card {
  background-color: $light-green;
  border-left: 8px solid $dark-green;
}

.voilet-card {
  background-color: $v-light-voilet;
  border-left: 8px solid $light-voilet;
}

.orange-card {
  background-color: $light-orange;
  border-left: 8px solid $orange;
}

.blue-card {
  background-color: $light-blue;
  border-left: 8px solid $dark-blue;
}

.red-card {
  background-color: $light-red;
  border-left: 8px solid $dark-red;
}

.info-card {
  background-color: #65fff79a;
  border-left: 8px solid #05947c;
}

.green-progrees-dot {
  background: $dark-green;
  color: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
  margin: 0 10px;
}

.yellow-progrees-dot {
  background: $dark-yellow;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 19px;
  margin: 0 10px;
}

.blue-progrees-dot {
  background: $dark-blue;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 19px;
  margin: 0 10px;
}

.red-progrees-dot {
  background: $dark-red;
  color: #fff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
  margin: 0 10px;
}

.border-progrees-dot {
  background: white;
  border: 1px solid $dark-grey;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 19px;
  margin: 0 10px;
}

.grey-progrees-dot {
  background: $light-grey;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 19px;
  margin: 0 10px;
}

.bg-red {
  background-color: $medium-red !important;
}

.bg-green {
  background-color: $medium-green !important;
}

.bg-yellow {
  background-color: $medium-yellow !important;
}

.bg-dark-blue {
  background-color: $dark-blue !important;
}

.bg-dark-green {
  background-color: $dark-green !important;
}

.bg-light-voilet {
  background-color: $light-voilet !important;
}

.bg-dark-pink {
  background-color: $dark-pink !important;
}

.bg-dark-yellow {
  background-color: $dark-yellow !important;
}

.userlist-card {
  p {
    font-size: 14px;
    color: $heading-text;
    letter-spacing: 0.3px;
  }

  span {
    font-size: 12px !important;
    font-weight: 300;
  }
}

.days-code-hero {
  background-image: url("../images/bg/days-hero.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.waiting-section {
  ul {
    li {
      list-style: none;

      span {
        font-size: 16px;
      }
    }
  }

  font-weight: 300;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 20px 0 5px 25px;

      &::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        margin-top: 2px;
        margin-left: -25px;
        display: table;
        float: left;
        color: $light-voilet;
        font-weight: 500;
      }
    }
  }
}

// .problem-popover {
//   position: fixed;
//   bottom: 20px;
//   right: 25px;
//   z-index: 999;
//   .problem-popover-box {
//     cursor: pointer;
//     border-radius: 5px;
//     padding: 15px;
//     background-color: $light-voilet;
//     color: #ffffff;
//   }
// }

.lesson-texteditor {
  .rdw-editor-main {
    height: 500px;
  }

  pre {
    background-color: rgb(16, 46, 70);
    color: #ffffff;
    padding: 12px;
    border-radius: 5px;

    span,
    p {
      color: #ffffff !important;
    }
  }
}

.lesson-content {
  margin-left: auto !important;
  margin-right: auto !important;
}

.lesson-content,
.lesson-texteditor,
.quiz-frame {
  img {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }

  pre {
    background-color: rgb(16, 46, 70);
    color: #ffffff;
    padding: 12px;
    border-radius: 5px;

    span,
    p {
      color: #ffffff !important;
    }
  }

  table {
    td {
      border: 1px solid $light-blue;
    }
  }

  ul {
    li {
      padding-top: 15px;
    }
  }

  p {
    font-size: 16px;
    letter-spacing: 0.5px !important;
    color: #1f1f1f;
    font-family: "Google Sans Text", "Roboto", "Helvetica Neue", Helvetica,
      sans-serif !important;
    line-height: 1.25rem;
  }

  h2 {
    border-bottom: 1px solid $heading-text;
    padding-bottom: 10px;
  }

  blockquote {
    border-left: 3px solid #30006d;
    margin-top: 30px;

    span {
      padding-left: 5px;
    }
  }
}

.lesson-content-index {
  .index-list-ul {
    list-style: none;
    padding: 0;
    border-left: 1px solid $light-grey;

    li {
      padding: 5px;
      cursor: pointer;
      margin: 10px 0;

      &:hover {
        border-left: 2px solid $light-voilet;
        color: $light-voilet;
      }
    }

    .active-topic {
      border-left: 2px solid $light-voilet;
      background-color: #f5f5f5;
    }
  }
}

.quiz-challenge-option {
  .mt-2 {
    margin-top: 0 !important;
  }

  .option-text {
    display: flex !important;
    align-items: center !important;
    margin: 0;

    .text-left {
      margin-top: 0 !important;
    }
  }
}

.quiz-count-badge {
  background-color: $light-voilet;
  border-radius: 30px;
  padding: 3px 8px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.quiz-dot-status {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .quiz-dot {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 5px;
    background: #fff;
    border: 1px solid $heading-text;
    color: #000;
    text-align: center;
  }

  .active {
    background: $dark-blue;
    color: #fff;
  }
}

.quiz-frame {
  p {
    font-size: 14px;
    font-weight: 300;
  }
}

.content-steps-header {
  .active {
    color: $light-voilet;
    font-weight: 600;
  }

  .content-section-steps {
    .col-4 {
      i {
        margin-right: 10px;
      }

      span {
        background-color: #fff;
        padding: 0 10px;
      }
    }

    .col-4::before {
      position: absolute;
      content: "";
      border-bottom: 2px solid #ccc;
      width: 100%;
      top: 25px;
      left: 0%;
      z-index: -1;
    }
  }
}

// program-steps

.program-steps-container {
  position: absolute;
  top: 17%;
  left: 0%;
  display: block;
  align-items: center;
  justify-content: center;
}

.program-steps-progressbar {
  counter-reset: step;
}

.program-steps-progressbar li {
  list-style-type: none;
  width: 16%;
  float: left;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
  top: -10px;

  i {
    font-size: 30px;
    margin-bottom: 5px;
    position: relative;
    top: -90px;
  }

  p {
    position: relative;
    top: -30px;
    font-size: 12px;
  }
}

.program-steps-progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.program-steps-progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.program-steps-progressbar li:first-child:after {
  content: none;
}

.program-steps-progressbar li.active {
  color: green;
}

.program-steps-progressbar li.active:before {
  border-color: #55b776;
}

.program-steps-progressbar li.active + li:after {
  background-color: #55b776;
}

.daily-task-card {
  border-radius: 7px !important;

  .morning {
    background-color: #ff4e00;
    background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%);

    h6 {
      color: #ffffff !important;
    }
  }

  .noon {
    background-image: linear-gradient(135deg, #fdeb71 10%, #f8d800 100%);
    border: #000000;
  }

  .evening {
    background-color: #537895;
    background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);

    .heading-text,
    p,
    h6 {
      color: #ffffff !important;
    }
  }

  .daily-task-card-body {
    // background-color: #f5f5f5;
    h6 {
      font-weight: 500;
    }

    .certificate-progress-text {
      font-size: 16px;
    }
  }

  .step-dot {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    // position: absolute;
    padding: 5px;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    text-align: center;
  }
}

.certificate-progress {
  .progress {
    height: 30px !important;
  }
}

.dashabord-header-icons {
  i {
    &:hover {
      background-color: #f5f5f5d3;
      border-radius: 5px;
      color: #30006d !important;
    }
  }
}

.dayscode-roadmap {
  .roadmap-title {
    font-size: 18px;
    font-weight: 400;
  }
}

.team-section-nav {
  .list {
    &:hover {
      background-color: #f5f5f560;
    }
  }
}

.days-setting-layout {
  .side-nav {
    position: fixed;
    left: 0;
    z-index: 10;
  }
}

.speaker-card {
  min-height: 260px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 3px #626c70;
  border-radius: 15px;
  text-align: center;

  .dlt-card-btn {
    width: 20px;
    height: 20px;
    color: #673de6;
    cursor: pointer;

    &:active {
      background-color: #673de6;
    }
  }

  .remove-btn {
    &:active {
      background-color: #673de6;
    }
  }
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

  .add-guest {
    padding: 5px 10px;
    color: #fff;
    background-color: #673de6;
    font-weight: 600;
    border: none;
    border-radius: 5px;

    &:hover {
      background-color: transparent;
      color: #673de6;
      border: 1px solid #673de6;
    }
  }
}

.event-speaker-search-table {
  .participant-search-card {
    padding: 10px;
    margin: 10px;
    border: 1px solid #673de6;
    border-radius: 10px;
    display: flex;

    h6 {
      font-size: 14px;
    }

    p {
      font-size: 10px;
    }
  }

  .speaker-search-card {
    padding: 10px;
    margin: 10px;
    border: 1px solid #673de6;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    h6 {
      font-size: 14px;
    }

    p {
      font-size: 10px;
    }
  }

  .add {
    color: #673de6;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  // .problem-popover {
  //   position: fixed;
  //   bottom: 20px;
  //   right: 10px;
  //   z-index: 1;
  // }
  // .code-dashboard {
  //   h1 {
  //     font-size: 30px;
  //   }
  //   p {
  //     font-size: 14px;
  //   }
  // }

  .code-dashboard {
    .qoute {
      h6 {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }

  .upper-header {
    h4 {
      font-size: 16px;
    }
  }

  .solution-counter {
    h1 {
      font-size: 20px;
    }
  }

  h1 {
    font-size: 22px;
  }

  .dashboard-btn {
    align-items: center;
    font-size: 14px;
  }

  .problems-side-section {
    .row {
      padding: 0;
    }
  }

  .problem-statment {
    font-size: 30px;
  }

  .side-section-drawer.open {
    width: 100% !important;
  }

  .side-section-drawer .page-content-wrapper {
    width: 100% !important;
  }

  .side-section-drawer.open + .page-content-wrapper {
    width: 100% !important;
  }

  .page-content-wrapper {
    border: none;
  }

  .solution-nav {
    #toggle-icon {
      display: none;
    }
  }

  .problem-banner-box {
    h2 {
      color: $light-voilet;
      text-align: center;
      font-size: 16px;

      i {
        font-size: 16px;
        padding: 5px;
      }
    }
  }

  .leaderboard-section,
  .notification-section {
    overflow-y: scroll;
    height: 300px;
  }

  .enroll-btn {
    align-items: center !important;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .about-code-section {
    h6 {
      margin-top: 20px;
      font-size: 18px;
    }

    h1 {
      font-size: 22px !important;
      white-space: nowrap !important;
    }
  }

  .why-join-us {
    align-items: center;

    h6 {
      font-size: 16px;
    }

    h1 {
      font-size: 22px;
    }
  }

  .learning-objective {
    img {
      height: 100px;
      width: 100px;
    }
  }

  .problem-section,
  .lesson-section,
  .solution-section,
  .practice-section,
  .daily-task-card-section {
    height: 70vh;
  }

  .lesson-content-index {
    display: none;
  }

  .challenge-section {
    height: 60vh;
  }

  .side-section-drawer.open .problems-side-section {
    height: 50vh;
  }

  .daily-task-card {
    .daily-task-card-body {
      h6 {
        font-size: 14px;
        letter-spacing: 1.2px;
      }
    }

    .certificate-progress-text {
      font-size: 15px;
      font-weight: 500;
    }

    .assigment-status-text {
      span {
        font-size: 14px !important;
      }
    }

    h1 {
      font-size: 30px;
    }

    .step-dot {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 5px;
      border: 1px solid #000000;
      color: #000000;
      text-align: center;
    }
  }

  .large-text {
    font-size: 60px;
  }

  .content-steps-header {
    .content-section-steps {
      font-size: 14px;

      .col-4::before {
        top: 9px;
      }

      .task-icon {
        display: none;
      }
    }
  }

  .quiz-dot-status {
    ul {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .quiz-dot {
      margin-right: 10px;
    }
  }

  .code-setting-side-nav {
    // margin: 0 10px 15px 10px;
    // ul {
    //   li {
    //     list-style: none;
    //     text-decoration: none !important;
    //     margin: 0 10px;

    //     span {
    //       border: 1px solid #353833;
    //       border-radius: 10px;
    //       padding: 10px;
    //       cursor: pointer;

    //       &.active {
    //         color: #30006d;
    //         border-radius: 5;
    //         border-right: none;
    //         border-bottom: 5px solid #30006d !important;
    //         background-color: #f5f5f5;
    //       }
    //     }
    //   }
    // }
  }

  // .days-setting-layout {
  //   .side-nav {
  //     position: inherit;
  //   }
  // }

  .assigment-section {
    .event-dashboard-header {
      ul {
        li {
          span {
            font-weight: 400 !important;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .give-away-alert {
    img {
      height: 150px;
      width: 150px;
    }

    h1 {
      font-size: 24px;
    }

    p {
      display: none;
    }
  }

  .dayscode-roadmap {
    .roadmap-title {
      font-size: 14px;
      font-weight: 400;
      padding-left: 8px;
    }
  }
}

.batch-header-section {
  border: 1px solid #ece4f2;
  border-radius: 14px;
  background-color: #ece4f2;

  h5 {
    font-size: 26px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }

  &:hover {
    background-color: #decfe8;
  }
}

.batch-content-header {
  margin: 1rem 2rem 0 2rem;
  .problem-heading {
    color: #071437;
    h3 {
      color: #071437;
      font-size: 1.8rem;
      margin: 0;
    }
    span {
      font-size: 0.9rem;
      color: #666565;
      margin: 0;
      cursor: pointer;
      span {
        font-weight: 500;
        color: #666565;

        &:hover {
          color: #6f00ff;
        }
      }
    }
  }
}

.lessons-heading {
  color: #9dc269;
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}

.quiz-heading {
  color: #9dc269;
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}

.mentor-heading {
  color: #9dc269;
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}

.participants-heading {
  color: #9dc269;
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}

.roadmap-heading {
  color: #9dc269;
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}

.mentor-card {
  min-height: 250px;
  margin: 10px;
  text-align: center;
  overflow: hidden;
  position: relative;
  border: 2px solid #efedf5;
  border-radius: 15px;
  margin: 40px 80px 40px 80px;

  .dlt-card-btn {
    width: 20px;
    height: 20px;
    color: #f0eef5;
    cursor: pointer;

    &:active {
      background-color: #673de6;
    }
  }

  .remove-btn {
    &:active {
      background-color: #673de6;
    }
  }

  .upper-card-portion {
    background-image: url("../images/bg/days-hero.jpeg");
    background-size: cover;
    height: 150px;
  }

  .mentor-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: -50px auto -30px auto;
    border: 2px solid black;
  }

  .mentor-details {
    margin-top: 64px;
    margin-bottom: -75px;
    height: 200px;

    p {
      font-size: 20px;
      font-weight: 700;
    }

    h5 {
      font-size: 15px;
      font-weight: 300;
    }
  }

  .mentor-image img {
    display: block;
    height: auto;
    width: 100%;
  }
}
.problem-list-card {
  border-radius: 1.5rem;
  &:hover {
    transform: scale(1.01);
    background-color: #fafafa;
  }
}
.side-navbar-icons {
  font-size: 18px;
}

// Batch Overview
.overview-content {
  background: #fcf9f9;
  .overview-header {
    background: #f5f5f5;
    .header-days {
      font-size: 50px;
      font-family: fantasy;
      background: #beb2e0;
      padding: 10px;
      border-radius: 30px;
    }
    .status {
      background: #673de6;
      border-radius: 5rem;
      color: white;
      font-weight: bold;
    }
    .batch-edit {
      border-radius: 10px !important;
      &:hover {
        border-radius: 10px !important;
        background-color: #673de6de;
        cursor: pointer;
      }
    }
  }
  .overview-user-content {
    background-color: white;
    .overview-details {
      width: 400px;
    }
    .overview-user-content-icons {
      font-size: 40px;
    }
    .user-content-hover-icons {
      &:hover {
        color: #673de6;
      }
    }
  }
  .overview-details-buttons {
    border: 2px solid #673de6 !important;
    border-radius: 2rem;
    &:hover {
      background-color: #673de6;
      color: white;
    }
    span {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .overview-graph {
    height: 600px;
    background-color: white;
    img {
      height: 500px !important;
    }
  }
  .overview-roadmap {
    height: 600px;
    background-color: white;
    img {
      height: 500px !important;
      padding: 20px;
    }
  }
  .batch-content-details {
    background: white;
    span {
      border-radius: 50px;
      background-color: #a696d8;
      margin: auto;
      padding: 0 20px;
    }
  }
}
.class-attendance {
  max-height: 250px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1efef;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }
}

.filter-Modal-content {
  position: relative !important;
  z-index: 10;
  .filter {
    position: absolute !important;
    top: 35px !important;
    // left: -60px;
    right: 0px;
    width: 250px;
    height: fit-content;
    background-color: #ffffff;
    border: 0.1rem solid #7d7f852a;
    box-shadow: 3px 3px 3px #7d7f852a;
    border-radius: 5px;
    padding: 20px;
    span {
      font-size: 15px;
      font-family: $font-fam;
      font-weight: 600;
    }

    .empty-line {
      border-top: 1px solid #c2c2c2b2;
      height: 0.5px;
      width: 100%;
      margin: 10px 0 20px 0;
    }
    .form-label {
      font-size: 15px !important;
      font-family: $font-fam;
      font-weight: 600;
    }
    .form-check {
      label {
        font-size: 1rem !important;
        font-family: $font-fam !important;
        font-weight: 400;
        margin-left: 10px;
        margin-right: 10px;
        color: #4b5675;
      }
    }
    .filter-buttons {
      display: flex;
      justify-content: end;
      button {
        padding: 2px 8px !important;
        font-size: 14px;
      }
    }
  }
}
.filter-modal-button {
  background-color: #e3e6f0;
  &:hover {
    color: rgb(150, 143, 143) !important;
    border: 1px solid #dbdfe9 !important;
    background-color: #dbdfe9 !important;
  }
}

.batch-dashboard-header-nav {
  border-bottom: 0.1px solid #868994;
  overflow-y: hidden;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-top: 5px;
    margin-bottom: 6px;
    li {
      margin: 5px 10px 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      .batch-nav-elemnets {
        color: #4b5675;
        font-family: $font-fam;
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: -6px;
        padding: 5px;
        cursor: pointer;
        border-radius: 5px;

        .badge {
          background-color: #9dc269;
          border-radius: 50%;
          color: #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          margin-left: 2px;
          height: 20px;
          text-align: center;
          width: 20px;
        }

        &:hover {
          background-color: #7948f442;
        }
        &.active {
          border-radius: 5px 5px 0 0;
          border-bottom: 4px solid #7848f4;
        }
      }
    }
  }
}

.batch-dashboard-header {
  border-bottom: 1px solid #ddd;
  ul {
    // margin-top: 20px;
    margin-bottom: 0;
    display: flex;
    padding: 0;
    border-bottom: 1px solid #ddd;
    li {
      list-style: none;
      span {
        cursor: pointer;
        font-weight: 500;
        padding: 5px 20px;
        margin: 5px;
        color: #000;
        // font-size: 14px;
        border: 1px solid #000;
        .badge {
          background-color: #9dc269 !important;
          border-radius: 50%;
          width: 20px !important;
          height: 20px !important;
          color: #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          padding: 5px !important;
          border: none !important;
        }

        &.active {
          border: 1px solid #673de6 !important;
          color: white !important;
          font-weight: 700;
          font-size: 16px;
          background-color: #673de6 !important;
        }

        &.active::before {
          width: 3px;
          background-color: #30006d;
        }

        &:hover {
          background-color: #673de6;
          border: 1px solid #673de6;
          color: #fff;
        }

        &:focus {
          text-decoration: none;
        }

        .overview-nav-img {
          margin-left: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .days-setting-layout {
    .side-nav {
      background-color: white;
      z-index: 10;
      position: fixed;
    }
  }
  .mobile-reponsive-close {
    width: 15% !important;
  }
  .mobile-responsive-open {
    width: 50% !important;
  }
  .children-props {
    padding-left: 15% !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 1050px) {
  .days-setting-layout {
    .side-nav {
      background-color: white;
      z-index: 10;
      position: fixed;
    }
  }
  .mobile-reponsive-close {
    width: 10% !important;
  }
  .mobile-responsive-open {
    width: 50% !important;
  }
  .children-props {
    padding-left: 10% !important;
  }
}

.table {
  width: 100%;
  text-align: center;
}

.table th,
.table td {
  text-align: center !important;
}
.days-card-container {
  .days-head {
    font-size: x-large;
    font-weight: 500;
  }
  .days-card {
    height: 70px;
    width: 70px;
    border-radius: 15px;
  }
}

.custom-drawer .ant-drawer-content-wrapper {
  width: 700px !important; /* Set your desired width here */
}

.title-heading {
  font-size: x-large;
  font-weight: 500;
}
.sub-title-heading {
  font-size: large;
  font-weight: 400;
}
.days-card {
  height: 70px;
  width: 70px;
  border-radius: 15px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px !important;
    margin: 1.75rem auto;
  }
}

.batch-card-container {
  transition: transform 0.1s;
}
.batch-card-container:hover {
  transform: scale(1.02);
}
