@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
$event_light_bg: #f9fbfc;
$light_heading_font: "Quicksand", sans-serif;
$general-font-fam: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
$stat-font-fam: "Inter", sans-serif;
$heading_color: #122f4c;
$font-fam: "Inter", sans-serif;
$event_default_bg: #f2f2f2;

.mt-n3 {
  margin-top: -2.5rem !important;
}

// Event side nav
.event-side-nav {
  ul {
    display: flex;
    flex-direction: column;

    li {
      list-style: none;

      a {
        padding: 10px 24px;
        display: flex;
        align-items: center;
        gap: 12px;
        color: #626c70;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        &:hover {
          background-color: #c3c3c324;
        }
      }
    }
  }
}

// Event Page
.event-page-header {
  min-height: 400px;
  background-color: #603f83ff;

  .event-heading {
    color: #c7d3d4ff;
    font-size: 55px;
    font-weight: 300;
    font-family: $light_heading_font;
  }

  .event-sub-heading {
    color: #c7d3d4ff;
    font-size: 20px;
    font-weight: 200;
  }

  .event-date-time {
    color: yellow;
    font-size: 18px;
    font-weight: 400 !important;
  }

  .event-header-tag {
    font-size: 14px;
    color: #000;
    padding: 2px 5px;
    border: 1px solid #726f77;
    border-radius: 50px;
    margin-right: 7px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
  }

  .event-platform {
    background-color: $event_light_bg;
    border-radius: 5px;
    color: #30006d;
    font-weight: 500;
    font-size: 15px;
  }

  .event-catagory {
    background-color: $event_light_bg;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #30006d;
  }
}

.event-social-icons {
  display: flex;

  span {
    display: block;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 8px;
    background: #fff;
    border: 1px solid $event_light_bg;
    color: #000;
    text-align: center;
    margin-right: 20px;
    line-height: 20px;
  }
}

.event-reg-section {
  background-color: $event_light_bg;
}

.event-description {
  p {
    font-size: 20px;
  }
}

.register-text {
  font-size: 30px;
  font-family: 300 !important;
}

.custom-button {
  background-color: #ffffff !important;
  border-radius: 30px;
  color: #30006d;
  font-weight: 600;
  font-family: "Fira Code", monospace;
  padding: 8px !important;
  border: solid 1px #6e53bde7 !important;
  margin-top: 35px;
  width: 170px;
  height: 46px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;
  align-items: center;

  > span {
    text-align: center;
    font-weight: 600;
    font-family: "Fira Code", monospace;
    align-items: center;
    color: #30006d;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
    white-space: nowrap;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #6e53bde7;
    position: absolute;
    transition: all 0.5s ease;
  }

  &:hover {
    span {
      color: #ffffff !important;

      i {
        color: #ffffff;
      }
    }
  }
}

.reg-button {
  background-color: #30006d;
  color: #ffffff;
  font-weight: 400;
  border-radius: 7px;
  align-items: center;
  min-height: 50px;
  min-width: 100%;
}

.custom-button {
  &:before {
    top: 0;
    left: -210px;
    color: #ffffff;
  }

  &:hover {
    &:before {
      left: 0;
      color: #ffffff;
    }
  }
}

//  Event List page

.create-event-promo {
  min-height: 100px;
  background-color: #30006d;

  h1 {
    color: #ffff;
    font-size: 23px;
  }
}

.event-carousel {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 11%,
    rgb(255, 255, 255) 35%
  ) !important;

  h1 {
    color: rgb(23, 3, 54);
  }

  h2 {
    color: rgb(20, 130, 0);
  }

  .qoute {
    h6 {
      color: rgb(43, 54, 45);
    }

    color: #000;
  }
}

.event-status-head {
  border-bottom: 1px solid #ddd;

  .event-state {
    padding: 15px;

    h1 {
      font-size: 35px;
      color: #17171ad0;
    }
  }
}

.event-list-section {
  background-color: #f8f9fa;
}

.event-list-nav {
  justify-content: flex-end;
  display: flex;
  align-items: center;

  .filter-icon {
    font-size: 30px;
  }

  .my-events {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.event-filter {
  position: absolute;
  top: 100%;
  right: 10%;
  z-index: 9;
  transition: all 0.5s ease-in-out;
  min-width: 50%;
  min-height: 300px;
  border-radius: 5px;
}
.event-card-list {
  min-height: 430px;
}

.event-promot-section {
  background-color: #30006d;
  color: #ffffff;
  min-height: 400px;

  h3 {
    font-size: 25px;
    color: white;
  }

  p {
    color: #ffffff;
    font-size: 16px;
    padding: 20px 50px;
  }
}

// Event Card

.event-card {
  border: solid 1px rgba(204, 204, 204, 0.842);
  border-radius: 10px;
  height: 100%;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:hover {
    // box-shadow: 0 5px 35px #99a7c571;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
    text-decoration-style: none !important;

    .hover-sutter {
      height: 40%;
      padding-top: 10px;
      background-color: #2f006d72;
    }
  }

  .hover-sutter {
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: #30006d;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.5s ease-in-out;
    bottom: 0;
    line-height: 18px;
    color: #fff;

    .btn {
      margin-top: 40px;
    }
  }

  .event-card-title {
    font-size: 18px;
    color: #17171ad0;
    text-decoration: none;
  }

  .event-card-sub-heading {
    font-size: 16px;
    color: rgb(59, 59, 59);
  }

  .reg-status-block {
    position: absolute;
    top: 5%;
    right: 0;
    color: white;
    padding: 8px 10px;

    // border-top-left-radius: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #e6f4ea;
    color: #1e8e3e;
    font-size: 10px;
    line-height: 11px;
  }

  .event-host-action-toggle {
    position: absolute;
    top: 5%;
    right: 0;
    color: white;
    padding: 8px 10px;
    // border-top-left-radius: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #e6f4ea;
    color: #1e8e3e;
    font-size: 10px;
    line-height: 11px;
  }

  .date-block {
    position: absolute;
    bottom: 50%;
    right: 5%;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #1671a6;
    text-align: center;
    color: #ffffff;

    h1 {
      font-size: 20px;
      margin-bottom: 0;
      color: #ffffff;
    }

    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  p {
    font-size: 15px;
    color: rgb(104, 103, 103);
    margin-bottom: 7px;
  }

  .event-card-footer {
    display: grid;
    align-items: stretch !important;
    bottom: 5%;
    width: 100%;
  }
}

// Featured Card

.featured-event-card {
  border: solid 1px rgba(204, 204, 204, 0.842);
  border-radius: 10px;
  height: 100%;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    text-decoration-style: none !important;

    .featured-card-hover-sutter {
      width: 100%;
      height: 100%;
      padding-top: 35px;
      background-color: #2f006d72;
    }
  }

  .featured-card-hover-sutter {
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 0%;
    height: 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;

    .header-class {
      background-color: #343a40;
      color: #fff;
      text-align: center;
    }

    .row-class {
      text-align: center;
    }

    .table .thead-dark th {
      color: #fff;
      background-color: #343a40;
      border-color: #454d55;
    }

    background-color: #30006d;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.5s ease-in-out;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    line-height: 18px;
    color: #fff;

    .btn {
      margin-top: 40px;
    }
  }

  .featured-event-img {
    height: 100%;
    width: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: 50%;
    background-color: #f1f3f4;
    background-position: center center;
    background-repeat: no-repeat;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0 !important;
  }

  .featured-event-title {
    font-size: 30px;
    color: #17171ad0;
    text-decoration: none;
  }

  .event-card-sub-heading {
    font-size: 16px;
    color: rgb(59, 59, 59);
  }

  .reg-status-block {
    position: absolute;
    top: 5%;
    right: 0;
    color: white;
    padding: 8px 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #e6f4ea;
    color: #1e8e3e;
    font-size: 10px;
    line-height: 11px;
  }

  .date-block {
    position: absolute;
    bottom: 5%;
    right: 10%;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #1671a6;
    text-align: center;
    color: #ffffff;

    h1 {
      font-size: 20px;
      margin-bottom: 0;
      color: #ffffff;
    }

    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  p {
    font-size: 15px;
    color: rgb(104, 103, 103);
    margin-bottom: 7px;
  }

  .event-card-footer {
    display: grid;
    align-items: stretch !important;
    bottom: 5%;
    width: 100%;
  }
}

.fuck-line {
  text-decoration: none !important;
}

.event-tag {
  font-size: 12px;
  color: #000;
  padding: 2px;
  // border: solid 1px pink;
  border-radius: 50px;
  margin-right: 7px;
  margin-top: 7px;
  text-decoration: none;
  text-align: center;
  display: inline-block;

  &:nth-child(1) {
    color: #694cea;
    border: solid 0.5px #694cea;
  }

  &:nth-child(2) {
    color: orangered;
    border: solid 0.5px orangered;
  }

  &:nth-child(3) {
    color: olivedrab;
    border: solid 0.5px olivedrab;
  }
}

// Event Dashboard :
.event-dashboard {
  .cover-image {
    width: 100%;
    min-height: 200px;
    background-color: #414141;
    background-size: 100% 250px;
    padding: 10px;
    background-repeat: no-repeat;
    .cover-img-util {
      margin: 0 10px;
      padding: 5px;
      background-color: #323232b2;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: white;
      &:hover {
        cursor: pointer;
        background: #494949b2;
      }
    }
    .cover-img-logo {
      width: 70px;
      height: 70px;
      color: white;
      background-color: #686666;
      background-repeat: no-repeat;
      background-size: 70px 70px;
    }
    .cover-img-heading {
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
    .date {
      font-weight: 300 !important;
      font-size: 16px;
    }
    .heading-des {
      font-size: 12px;
      font-weight: 100;
    }
  }

  .back-btn {
    width: 40px;
    height: 40px;
    color: #000;
    border-radius: 100%;

    &:hover {
      background-color: #673de6;
      color: #fff;
    }
  }
}

.event-dashboard-header {
  ul {
    margin-top: 20px;
    margin-bottom: 0;
    display: flex;
    padding: 0;
    border-bottom: 1px solid #ddd;

    li {
      list-style: none;

      span {
        cursor: pointer;
        font-weight: 500;
        display: block;
        padding: 6px 25px;
        margin: 5px;
        color: #000;
        font-size: 14px;
        border: 1px solid #000;
        border-radius: 20px;

        &.active {
          border: 1px solid #673de6;
          color: #673de6;
          font-weight: 700;
          font-size: 16px;
        }

        &.active::before {
          width: 3px;
          background-color: #30006d;
        }

        &:hover {
          background-color: #673de6;
          border: 1px solid #673de6;
          color: #fff;
        }

        &:focus {
          text-decoration: none;
        }

        .overview-nav-img {
          margin: 0 5px 0 0;
        }
      }
    }
  }
}

// Event overview Timeline
.event-timeline {
  background-color: $event_default_bg;
  h1 {
    color: #212529;
    font-size: 40px;
    font-weight: 800;
    margin: 0 15px;
  }
  .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #00000046;
    padding: 20px;

    .add-timeline-btn {
      font-size: 16px;
      font-weight: 600;
      background-color: #673de6;

      &:hover {
        color: #673de6;
        background-color: transparent;
        border: 1px solid #673de6;
      }
    }
  }
}

.add-new-timeline {
  .btn-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-secondary {
      background-color: #4e5557;

      &:hover {
        color: #4e5557;
        background-color: transparent;
        border: 1px solid #4e5557;
      }
    }

    .btn-primary {
      background-color: #673de6;

      &:hover {
        color: #673de6;
        background-color: transparent;
        border: 1px solid #673de6;
      }
    }
  }
}

.timeline-card {
  .main-card {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .title {
      width: 450px;
      height: 180px;
      margin: 20px;
      padding: 30px;
      box-shadow: 0 0 3px #626c70;
      border-radius: 20px;
      overflow: auto;
      cursor: pointer;

      .timeline-delete {
        display: flex;
        justify-content: space-between;

        .delete-btn {
          width: 26px;
          height: 26px;
          color: #673de6;

          &:hover {
            cursor: pointer;
            color: #000;
          }
        }
      }

      h6 {
        color: #4e5557;
        text-align: justify;
      }
    }

    .mid-style {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 30px;
      color: #673de6;

      .vertical-line {
        width: 1px;
        height: 100%;
        border: 1px solid #673de6;
      }
    }

    .date-time {
      margin: 0;
      padding: 0;

      .date {
        display: flex;
        gap: 20px;
      }

      .time {
        display: flex;
        gap: 20px;
      }
    }
  }
}

//event-form-layout
.event-form-layout {
  background-color: $event_default_bg;
  h1 {
    color: #212529;
    font-size: 40px;
    font-weight: 800;
    margin: 0 15px;
  }
  h3 {
    color: #212529;
    font-size: 35px;
    font-weight: 600;
  }
}

//Attendes Table css

.text-orange {
  color: #ff5e00;
  &:hover {
    color: #ff5e00;
  }
}
.text-green {
  color: #219e25;
  &:hover {
    color: #4caf50;
  }
}

.event-attendees {
  .header-class {
    text-align: center;
  }
  .header-date {
    cursor: pointer;
  }

  .text-orange {
    color: #ff5e00;
    &:hover {
      color: #ff5e00;
    }
  }
  .text-green {
    color: #219e25;
    &:hover {
      color: #4caf50;
    }
  }

  .table-top-options {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    span {
      color: #454d55bb;
      padding: 5px 10px;
      font-size: 20px;
      &:hover {
        cursor: pointer;
        color: #000;
      }
    }
    .filters {
      padding-top: 5px;
      font-size: 16px;
      .filters-list {
        border: 1px solid #454d55bb;
        border-radius: 5px;
        margin-right: 4px;
        padding: 2px 5px 2px 5px;
        color: #454d55bb;
        .close-icon {
          cursor: pointer;
        }
      }
    }
  }

  .table-options-search {
    padding: 10px 20px 10px 0px;

    .search {
      width: 150px;
      border: 1px solid #5555552d;
      border-radius: 5px;
      // display: block;
      padding: 5px 4px 5px 40px;
      background-position: left 10px center;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"%3E%3Cpath stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-4.35-4.35M17 10a7 7 0 11-14 0 7 7 0 0114 0z" /%3E%3C/svg%3E');
      background-repeat: no-repeat;
      background-size: 16px;
      background-color: #d4d3d338;
      &:focus-visible {
        border: 1px solid #5555552d;
        outline: none;
      }
    }
  }

  input[type="checkbox"] {
    height: 15px !important;
    width: 15px !important;
  }

  .event-attendees-title {
    font-size: 25px;
    color: #454d55;
    font-weight: bolder;
  }

  .event-downlaod-xlsx-btn {
    box-shadow: none !important;

    &:hover {
      background-color: #17a3b8d7 !important;
      border-color: #17a2b8 !important;
      color: #fff !important;
    }
  }

  .event-send-mail-btn {
    box-shadow: none !important;

    &:hover {
      background-color: #218838 !important;
      border-color: #218838 !important;
      color: #fff !important;
    }

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
  .filter {
    position: absolute !important;
    // top: 35px !important;
    // left: -60px;
    // right: 0px;
    width: 250px;
    height: fit-content;
    background-color: #ffffffe7;
    border: 0.1rem solid #7d7f852a;
    box-shadow: 3px 3px 3px #7d7f852a;
    border-radius: 5px;
    padding: 20px;
    // background: transparent;
    span {
      font-size: 15px;
      font-family: $font-fam;
      font-weight: 600;
    }

    .empty-line {
      border-top: 1px solid #c2c2c2b2;
      height: 0.5px;
      width: 100%;
      margin: 10px 0 20px 0;
    }
    .form-label {
      font-size: 15px !important;
      font-family: $font-fam;
      font-weight: 600;
    }
    .form-check {
      label {
        font-size: 1rem !important;
        font-family: $font-fam !important;
        font-weight: 400;
        margin-left: 10px;
        margin-right: 10px;
        color: #4b5675;
      }
    }
    .filter-buttons {
      display: flex;
      justify-content: end;
      button {
        padding: 2px 8px !important;
        font-size: 14px;
      }
    }
    .status {
      .rw-list-option {
        padding: 0.25rem 0.5rem !important;
      }
    }
  }
}
//overview Setting && create Event
.event-basic-details {
  background-color: $event_default_bg;
  .event-basic-details-heading {
    color: #212529;
    font-size: 40px;
    font-weight: 800;
  }
  .event-basic-details-short-heading {
    h4 {
      color: #212529;
      font-size: 25px;
      font-weight: 800;
    }
    h2 {
      color: #212529;
      font-weight: 800;
    }
    span {
      font-weight: 400;
      color: #7a7a84;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .input-note {
    b {
      color: #212529;
    }
  }
}
//Add Guest User
.add-guest-modal {
  position: relative;
  top: 5rem;
  .add-guest-modal-header {
    background-color: #673de6;
  }
  .add-guest-btn {
    background-color: #673de6 !important;
  }
}

// Overview Speaker
.speaker-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px 10px 30px 10px;
  background-color: #f4f2f7;
  border-radius: 12px;

  .add-speaker-btn {
    background-color: #673de6;
    font-weight: 600;
    color: #fff;

    &:hover {
      color: #673de6;
      background-color: transparent;
      border: 1px solid #673de6;
    }
  }
}

.no-speaker {
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.speaker-card {
  min-height: 260px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #673de6;
  border-radius: 15px;
  text-align: center;

  .dlt-card-btn {
    width: 20px;
    height: 20px;
    color: #673de6;
    cursor: pointer;

    &:active {
      background-color: #673de6;
    }
  }

  .remove-btn {
    &:active {
      background-color: #673de6;
    }
  }
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

  .add-guest {
    padding: 5px 10px;
    color: #fff;
    background-color: #673de6;
    font-weight: 600;
    border: none;
    border-radius: 5px;

    &:hover {
      background-color: transparent;
      color: #673de6;
      border: 1px solid #673de6;
    }
  }
}

.event-speaker-search-table {
  .speaker-search-card {
    padding: 10px;
    margin: 10px;
    border: 1px solid #673de6;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    h6 {
      font-size: 14px;
    }

    p {
      font-size: 10px;
    }
  }

  .add {
    color: #673de6;
    cursor: pointer;
  }
}

// Overview sponsor
.sponsor-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .add-sponsor-btn {
    background-color: #673de6;
    font-weight: 600;
    color: #fff;

    &:hover {
      color: #673de6;
      background-color: transparent;
      border: 1px solid #673de6;
    }
  }
}

.event-sponsor-search-table {
  .sponsor-search-card {
    padding: 10px;
    margin: 10px;
    border: 1px solid #673de6;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    h6 {
      font-size: 14px;
    }

    p {
      font-size: 10px;
    }
  }

  .add {
    color: #673de6;
    cursor: pointer;
  }
}

.sponsor-card {
  min-height: 260px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 3px #626c70;
  border-radius: 15px;
  text-align: center;

  .dlt-card-btn {
    width: 20px;
    height: 20px;
    color: #673de6;
    cursor: pointer;
  }

  .remove-btn {
    &:active {
      background-color: #673de6;
    }
  }
}

.final-add-btn {
  display: flex;
  gap: 10px;

  button {
    background-color: #694cea;
    border: 1px solid #673de6;

    &:hover {
      background-color: transparent;
      color: #673de6;
      border: 1px solid #673de6;
    }
  }
}

// Forms layout
.form-group {
  width: 100%;
  margin-top: 10px;
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;

  .add-new-btn {
    background-color: #673de6;

    &:hover {
      background-color: transparent;
      color: #673de6;
      border: 1px solid #673de6;
    }

    &:active {
      background-color: #673de6;
      color: #fff;
    }
  }
}

.form-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    text-align: center;
    margin: 10px;
  }

  form {
    margin: 10px;
  }

  .input-field {
    margin: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-weight: 600;
      margin-top: 20px;
    }
  }

  .save-btn {
    font-size: 20px;
    padding: 5px;
    margin: 15px 20px;
    text-align: center;
    background-color: #673de6;
    color: #fff;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      border: 1px solid #673de6;
      color: #673de6;
    }
  }
}

.dot-icon {
  cursor: pointer;
  color: #673de6;
}

.icon {
  margin-right: 5px;
}

.move-btn {
  background-color: #673de6;

  &:active {
    background-color: #673de6;
    color: #fff;
  }
}

.dlt-btn {
  background-color: #673de6;

  &:active {
    background-color: #673de6;
    color: #fff;
  }
}

.edit-btn {
  background-color: #673de6;

  &:active {
    background-color: #673de6;
    color: #fff;
  }
}

.form-check {
  margin: 5px 10px;

  .form-check-label {
    margin-left: 5px;
  }
}

.new-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .cancel-form {
    width: 100px;
    padding: 10px;
    background-color: #4e5557;
    color: #fff;
    border-radius: 5px;

    &:hover {
      color: #4e5557;
      background-color: transparent;
      border: 1px solid #4e5557;
    }
  }

  .add-form {
    width: 150px;
    padding: 10px;
    background-color: #673de6;
    color: #fff;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      border: 1px solid #673de6;
      color: #673de6;
    }
  }
}

.event-dashboard-header {
  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #f1efef;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 1px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }
}

.link-gift-icon {
  color: #726f77;
  font-size: 65px;
}

// Event Create Form
// .event-reg {
//}

.form-shadow {
  inset: 0px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  background: rgb(255, 255, 255);
}

.cancel-img-btn {
  color: rgb(238, 61, 61);
  &:hover {
    // font-size: 20px;
    // background-color: #694cea;
    // padding: 5px;
    // border-radius: 50%;
    color: #fa1d1d !important;
    cursor: pointer;
  }
}

.event-speaker-search-table {
  .speaker-card {
    &:hover {
      background-color: $event_light_bg;
    }
  }
}

.event-dashboard-side-nav {
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      border: 2px solid $light_heading_font !important;
      display: flex;
      border-radius: 5px;

      .side-img {
        margin-right: 8px;
        font-size: 18px;
      }

      &:hover {
        background-color: #c3c3c324;
        cursor: pointer;
      }

      span {
        &.active {
          color: #fff;
        }
      }
      &.active {
        background-color: #673de6;
      }
    }
  }
}

.ticket-setting {
  background-color: $event_default_bg;
  // height: 92vh;
  // .ticket-setting-header {
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  //   align-items: center;
  //   padding-top: 2rem;
  //   h1 {
  //     font-size: 45px;
  //     color: $heading_color;
  //   }
  //   div {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;
  //     h3 {
  //       color: $heading_color;
  //       font-size: 24px;
  //       line-height: 30px;
  //     }
  //     span {
  //       color: #7a7a84;
  //       line-height: 24px;
  //     }
  //   }
  // }
  .ticket-setting-content {
    border-top: 1px solid #dee2e6;
    .title-section {
      h1 {
        color: #212529;
        font-size: 40px;
        font-weight: 800;
      }
      h4 {
        color: #212529;
        font-size: 25px;
        font-weight: 800;
      }
      span {
        font-weight: 400;
        color: #7a7a84;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .ticket-setting-form {
    background-color: #ffffff;
    .close-icon {
      font-size: 2rem;
      margin-top: 5px;
      margin-left: 5px;
      // color: rgb(228, 52, 52) ;
      cursor: pointer;
    }
    .input-with-counter {
      span {
        position: relative;
        color: #7a7a84;
        right: 40px;
      }
    }
    .label-name {
      line-height: 16px;
      color: #3d4f58;
      font-weight: 600;
      font-size: 16px;
    }
    .no-limit {
      color: #7a7a84;
      text-wrap: nowrap;
      input {
        height: 15px;
        width: 15px;
      }
    }
    // .operation-btn {
    //   background-color: #673de6;
    //   border: none;
    //   border-radius: 4px;
    //   padding: 5px 10px;
    //   color: #fff;
    //   &:hover {
    //     background-color: #5c3ccf;
    //   }
    // }
    .form-control[readonly] {
      background-color: #fff !important;
    }
    .operation-btn {
      background-color: transparent;
      color: #673de6;
      border: none;
    }
    .save-btn {
      background-color: transparent;
      color: #673de6;
      border: none;
    }
    .edit-ticket-heading {
      font-size: 20px;
      color: #3d4f58;
      margin-left: -10px;
      padding-top: -10px;
    }
    .custom-dropdown {
      border-radius: 8px !important;
    }
  }
}

//event-images
.overview-event-images {
  background-color: $event_default_bg;
  .event-images-header {
    h1 {
      color: #212529;
      font-size: 40px;
      font-weight: 800;
    }
    span {
      font-weight: 400;
      color: #7a7a84;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .event-images {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px 0px;
    margin-top: 15px;
    .event-images-frame {
      margin: 0 10px;
      height: 400px;
      width: 50%;
      img {
        border: 1.5px solid rgba(0, 0, 0, 0.301);
        border-radius: 10px;
        object-fit: cover;
      }
    }
    .event-images-array {
      overflow-y: scroll;
      scrollbar-width: none !important;
      height: 400px;
      min-width: 350px;
      max-width: 400px;
      border-radius: 10px;
      background-color: $event_light_bg;
      padding: 1rem 2rem;
      .event-image-name {
        // background-color: #e5e5e5;
        position: relative;
        background-color: rgba(0, 0, 0, 10%);
        border-radius: 5px;
        padding: 5px 10px;
        margin: 5px 0;
        span {
          font-size: 16px;
          font-weight: 600;
          color: #212121a8;
        }
        img {
          border-radius: 10px;
        }
        .delete-image-btn {
          position: absolute;
          top: 17px;

          right: 5px;
          color: rgb(240, 69, 69);
          &:hover {
            cursor: pointer;
            color: rgb(255, 0, 0);
          }
        }
      }

      h2 {
        color: #21212194;
        font-weight: 600;
        font-size: 23px;
      }

      .upload-btn {
        // position: fixed;
        // bottom: 0px;
        margin-top: 10px;
        width: 40%;
        align-self: self-end;
        justify-self: end;
        background-color: #673de6 !important;
        &:hover {
          background-color: #673de6d8 !important;
        }
      }
    }
  }
}
//event-resource
.event-resource {
  background-color: $event_default_bg;
  .event-resource-header {
    h1 {
      color: #212529;
      font-size: 40px;
      font-weight: 800;
    }
    span {
      font-weight: 400;
      color: #7a7a84;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .event-resource-content {
    h3 {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .event-resource-list {
  }
}
.link-preview-card {
  position: relative;
  background-color: #d6d6d6;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  .link-preview-card-content {
    display: flex;
    align-items: center;
    width: 100%;
    img {
      border-radius: 10px;
      width: 70px;
      height: 70px;
    }
    div {
      margin: 0 5px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 16px;
        color: $light_heading_font;
      }
      p {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .link-preview-card-setting {
    position: absolute;
    top: 0px;
    right: 5px;
    &:hover {
      color: #673de6;
      cursor: pointer;
    }
  }
}
.upload-btn {
  background-color: #673de6 !important;
  &:hover {
    background-color: #673de6d8 !important;
  }
}

//Event Content Management System (CMS)
.event-cms {
  background-color: $event_default_bg;
  .heading {
    color: #212529;
    font-size: 30px;
    font-weight: 800;
  }
  .event-cms-header {
    h1 {
      color: #212529;
      font-size: 40px;
      font-weight: 800;
    }
    span {
      font-weight: 400;
      color: #7a7a84;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .event-cms-outer-card {
    width: 100%;
    min-height: 600px;
    width: 100%;
    .outer-card-heading {
      h2 {
        font-size: 24px;
        font-weight: 700;
        color: $light_heading_font;
        span {
          color: #212121cb;
        }
      }
      h5 {
        font-size: 14px;
        font-weight: 400;
        color: #383838;
      }
      p {
        font-size: 13px;
        font-weight: 400;
        color: #7a7a84;
      }
    }
    .outer-card-body {
      span {
        font-size: 14px;
        font-weight: 400;
        color: #7a7a84;
      }
    }
    .event-cms-images {
      height: 300px !important;
      margin: 30px 0px;
    }
    .event-cms-inner-card {
      min-height: 250px;
      width: 225px;
      border-radius: 15px;
      img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
        object-fit: cover;
      }
      .badge {
        position: absolute;
        top: 5px;
        right: 0;
        background: #673de6 !important;
        // color: #673de6 !important;
        border: 1px solid #673de6 !important;
      }
      h4 {
        font-size: 22px;
        font-weight: 700;
        color: #212121;
      }
      h6 {
        font-size: 16px;
        color: #0000008f;
        font-weight: 400;
        // line-height: 10px;
      }
      p {
        font-size: 14px;
        color: #0000008f;
        font-weight: 400;
        // line-height: 10px;
      }
    }
  }
}

.event-general {
  font-family: $general-font-fam;
  background-color: #f2f2f2 !important;

  .stats {
    font-weight: 600;
    font-size: 21px;
    line-height: 41.6px;
    color: #3d4f58;
  }

  .sub-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;

    .text {
      color: #212121;
    }
  }

  .stat-card {
    font-family: $stat-font-fam !important;
    border-radius: 15px !important;

    .stat-heading {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    .stat-number {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: black;
    }

    .list {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: black;
    }
  }

  .description {
    background-color: #ffffff !important;

    .img-section {
      background-color: #e6e6e6 !important;
      height: 300px;

      .edit {
        background-color: #323232b2;
        color: #ffffff;
      }
    }
    .event-desc {
      font-weight: 400;
      font-size: 21px;
      line-height: 33.6px;
    }
  }

  .card {
    background-color: #ffffff !important;
    color: #212121;

    .card-head {
      font-weight: 400;
      font-size: 21px;
      line-height: 33.6px;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 22.4px;
    }

    .bio {
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      color: #6d6d6d !important;
    }
  }
}

.border-dashed {
  border: 1px dashed #00000081 !important;
  border-radius: 10px;
}

.border-dashed-purple {
  border: 1px dashed #673de6 !important;
  border-radius: 10px;
}

.rounded-image {
  border-radius: 50%;
  /* Makes the image rounded */
  width: 64px;
  /* Adjust the width as needed */
  height: 64px;
  /* Adjust the height as needed to ensure the image is square */
  object-fit: cover;
  /* Ensures the image covers the entire area */
}

.rounded-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #673de6;
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

//Event Insight scss
.event-insight-content {
  border: 1px solid white;
  border-radius: 6px;
  box-shadow: 0 0 3px #8d9192;
  .live-traffic-card {
    border: 1px solid #8d9192;
    border-radius: 6px;
  }
}
.event-add-speaker-modal {
  display: flex;
  justify-content: center;
  .upload-block {
    height: 150px !important;
    width: 150px !important;
    border: dashed 2px #726f77 !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    i {
      align-items: center !important;
      font-size: 40px;
      position: relative !important;
      top: 20px;
    }
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover !important;
    border: 2px solid black;
  }
}

@media (max-width: 767px) {
  .event-page-header {
    .event-heading {
      font-size: 40px;
    }

    .event-sub-heading,
    .event-organizer {
      font-size: 18px;
      font-family: 300 !important;
    }

    .event-date-icon {
      font-size: 20px;
    }
  }

  .event-description {
    p {
      font-size: 16px;
    }
  }

  .event-status-head {
    .event-state {
      padding: 10px;

      h1 {
        font-size: 25px;
      }
    }
  }

  .reg-button {
    min-width: 80%;
  }

  .event-carousel {
    h1 {
      font-size: 60px;
      text-align: left;
    }
  }

  main {
    justify-content: space-between;
  }

  .left {
    order: 2;
  }

  .right {
    order: 1;
  }

  .event-filter {
    top: 130%;
    right: 10%;
    min-width: 70%;
    min-height: 300px;
  }

  .event-card {
    position: relative;

    .reg-status-block,
    .event-host-action-toggle {
      right: 0;
    }
  }

  .event-promot-section {
    background-color: #30006d;
    color: #ffffff;
    min-height: 400px;

    h2 {
      font-size: 22px;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 20px;
      color: white;
    }

    p {
      color: #ffffff;
      font-size: 13px;
      padding: 10px 20px;
    }
  }
  .event-images {
    flex-direction: column !important;
    padding: 15px !important;
    .event-images-frame {
      width: 100% !important;
    }
    .event-images-array {
      min-width: 370px !important;
      margin-top: 20px !important;
    }
  }
}

@media (max-width: 950px) {
  .event-images {
    padding: 20px 0px;
    .event-images-frame {
    }
    .event-images-array {
      min-width: 300px;
    }
  }
}
