$section-bg: #f9f9fd;
$light-voilet: #7968ce;
$paratext: #676767;
$content-color: #8082a5;
$btn-bg: #7848f4;
$fs-heading: 40px;
$fs-body: 18px;
$gap-x: 5%;
$gap-y: 7%;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

.padding-x {
  padding-left: $gap-x;
  padding-right: $gap-x;
}
.padding-y {
  padding-top: $gap-y;
  padding-bottom: $gap-y;
}

.wittyhacks {
  .hero-section {
    img {
      width: 100%;
    }
  }
  //Details about wittyhack
  .details-panel {
    font-family: Poppins;
    span {
      color: #535151;
      font-size: 32px;
      font-weight: 300;
      display: flex;
      justify-content: center;
      line-height: 48px;
    }
    h1 {
      font-size: 96px;
      font-weight: 600;
      color: #000000;
      // line-height: 144px;
      @media screen and (max-width: 700px) {
        font-size: 50px;
      }
    }
    .gradient-line {
      width: 135px;
      height: 9px;
      background-image: linear-gradient(to right, #4a2dff, #961dcf, #ec0ca0);
      border-radius: 30px;
    }
    p {
      font-weight: 300px;
      font-size: 24px;
      line-height: 45px;
      width: 90%;
      margin: auto;
      @media screen and (max-width: 450px) {
        font-size: 18px;
      }
    }
    //Datcode logo
    .logo-shadow {
      box-shadow: 2px 4px 10px 2px #00000040 inset;
      margin: auto;
      border-radius: 26px;
      width: 660px;
      height: 250px;
      @media screen and (max-width: 700px) {
        width: 450px;
        height: 100px;
      }
      @media screen and (max-width: 480px) {
        width: 300px;
        height: 70px;
        border-radius: 10px;
      }
      img {
        width: 560px;
        height: 175px;
        top: 1552px;
        @media screen and (max-width: 700px) {
          width: 350px;
          height: 105px;
        }
        @media screen and (max-width: 480px) {
          width: 200px;
          height: 60px;
        }
      }
    }
    //WittyHack button
    .wittyhack-button {
      width: 350px;
      height: 70px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        91.49deg,
        #4a2dff -2.23%,
        #961dcf 51.19%,
        #ec0ca0 104.4%
      );
      color: #fff;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 600;
      line-height: 45px;
      letter-spacing: 0.05em;
      text-align: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(0.95);
      }
      @media screen and (max-width: 450px) {
        font-size: 16px;
        height: 50px;
        width: 200px;
      }
    }
  }
  //Digital badge
  .frame-section {
    // background-image: url(../images/svg/wittyhacks/Abract01.png);
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position-x: left;
    background-color: #f5f5f5;

    font-family: Poppins;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }
    .frame-download {
      background-color: #000000;
      width: 600px;
      position: relative;
      height: 600px;
      border-radius: 10px;

      @media screen and (max-width: 700px) {
        width: 400px;
        height: 400px;
      }
      @media screen and (max-width: 500px) {
        width: 325px;
        height: 325px;
      }
      img {
        border-radius: 10px;
        width: 100%;
        position: absolute;
        height: 100%;
      }
      .frame-img {
        z-index: 10;
      }
      .user-frame-img {
        top: 0px;
      }
    }
    label {
      border: 2px solid #7848f4;
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      width: 250px;
      border-radius: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #7968ce;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        border-color: #30006d;
        color: #30006d;
      }
    }
    button {
      border: 2px solid #7968ce;
      background-color: #7968ce;
      color: white;
      font-weight: 600;
      width: 250px;
      border-radius: 10px;
      height: 50px;
      &:hover {
        background-color: #fff;
        border-color: #30006d;
        color: #30006d;
      }
    }
    input {
      display: none;
    }
    .form-elements {
      @media screen and (max-width: 1100px) {
        justify-content: center;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .digital-badge {
      width: calc(100% - 600px);
      h1 {
        font-size: 62px;
        letter-spacing: 2px;
        @media screen and (max-width: 500px) {
          font-size: 40px;
        }
      }

      p {
        letter-spacing: 1px;
      }
      @media screen and (max-width: 1100px) {
        margin-top: 50px;
        flex-direction: column-reverse !important;
        width: 100%;
      }
      @media screen and (max-width: 700px) {
        margin-top: 50px;
        // flex-direction: column-reverse !important;
        width: 70%;
      }
      @media screen and (max-width: 500px) {
        margin-top: 50px;
        padding: 0px !important;
        // flex-direction: column-reverse !important;
        width: 100%;
      }
      .hashtag {
        color: #5424e7;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .caption {
    font-family: Poppins;
    border-radius: 6px;
    letter-spacing: 1px;
    color: black;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    .copy-clipboard {
      cursor: pointer;
      &:hover {
        font-size: large;
        color: #5424e7;
      }
    }
  }
  .wallpaper-layout {
    background-color: #fff;
    // background-color: #e6e3f6;
    h1 {
      font-family: Poppins;
      font-weight: 600;
      @media screen and (max-width: 500px) {
        font-size: 32px;
        justify-content: center;
      }
      @media screen and (max-width: 700px) {
        font-size: 32px;
      }
    }
    .desktop-wall-layout {
      @media screen and (max-width: 1000px) {
        justify-content: center;
      }
      @media screen and (max-width: 500px) {
        justify-content: center;
      }
    }
  }

  .desktop-wallpaper {
    border-radius: 20px;
    font-family: Poppins;
    font-weight: 600;
    a {
      &:hover {
        opacity: 80%;
        cursor: pointer;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
      }
    }

    .desktop-image {
      width: 400px;
      height: 250px;
      border-radius: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @media screen and (max-width: 1000px) {
        width: 600px;
        height: 350px;
      }
      @media screen and (max-width: 700px) {
        width: 450px;
        height: 250px;
      }
      @media screen and (max-width: 500px) {
        width: 350px;
        height: 200px;
        justify-content: center;
      }
      .download-wall {
        border-radius: 15px;
        font-size: 30px;
        width: 30px;
        background-color: #000000;
        color: #d4d2e4;
        &:hover {
          background-color: #5533ff;
        }
      }
    }
  }
  // .zoom-image {
  //   width: 400px;
  //   height: 250px;
  //   border-radius: 30px;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   @media screen and (max-width: 1000px) {
  //     width: 600px;
  //     height: 350px;
  //   }
  //   @media screen and (max-width: 700px) {
  //     width: 450px;
  //     height: 250px;
  //   }
  //   @media screen and (max-width: 500px) {
  //     width: 350px;
  //     height: 200px;
  //     justify-content: center;
  //   }
  //   .download-wall {
  //     border-radius: 15px;
  //     font-size: 30px;
  //     width: 30px;
  //     background-color: #000000;
  //     color: #d4d2e4;
  //     &:hover {
  //       background-color: #5533ff;
  //     }
  //   }
  // }
  .mobile-image {
    width: 260px;
    height: 450px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: 1000px) {
      width: 250x;
      height: 480px;
    }
    @media screen and (max-width: 700px) {
      width: 250px;
      height: 440px;
    }
    @media screen and (max-width: 500px) {
      width: 250px;
      height: 440px;
      justify-content: center;
    }
    .download-wall {
      border-radius: 15px;
      font-size: 30px;
      width: 30px;
      background-color: #000000;
      color: #d4d2e4;
      &:hover {
        background-color: #5533ff;
      }
    }
  }
}
