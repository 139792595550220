@import "./quiz_Dashboard/variables";

.contributor-sidenav {
  padding: 10px;
  text-align: left;
  h5{
    padding-left: 10px;
  }
  ul{
    margin:0px;
    padding: 0px 0px 0px 12px; 
    li{
      color:grey;
      list-style-type:none;
      padding:8px 0px;
      cursor: grab;
      font-size: 15px;
    }
    .active-tab{
      width:100%;
      background-color:rgba(255,245,220,255);
      border-left:5px solid  rgba(244,175,37,255);
      padding-left: 5px;
    }
  }
}
