.pagination {
  width: 80%;
  height: 15px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  color: #ffffff;
}

.nextBtn,
.previousBtn {
  padding: 10px;
  background-color: #30006d;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  outline: none;
}

.page {
  padding: 10px;
  background-color: #212529;
  border-radius: 8px;
  color: #ffffff;
  margin: 0 10px 0 10px;
  outline: none;
  cursor: pointer;
  :hover {
    color: rgb(184, 196, 194);
    cursor: pointer;
  }
}

.activeClassLink {
  background-color: #d4bef1;
  color: #30006d !important;
}

.disabled {
  background-color: #686868;
  color: #ffffff;
}

.no-data-block {
  z-index: -1;
  h2 {
    color: #686868;
  }
  // span {
  //   margin-top: 10px;
  //   // button {
  //   //   background-color: #1677ff;
  //   //   padding: 5px 10px;
  //   //   border-radius: 5px;
  //   //   border: none;
  //   //   color: #fff;
  //   //   &:hover {
  //   //     color: #fff;
  //   //     background: #4096ff;
  //   //   }
  //   // }
  // }
}

.custom-breadcum {
  span {
    font-size: 0.9rem;
    color: #666565;
    margin: 0;
    cursor: pointer;
    span {
      font-weight: 500;
      color: #666565;

      &:hover {
        color: #6f00ff;
      }
    }
  }
}
