@import "_variables";

.custom-table {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;

  .hover-effect {
    transition: color 0.3s ease;

    &:hover {
      color: #673de6;
    }
  }

  .form-select {
    width: 130px;
    cursor: pointer;
    &:focus {
      border-color: #673de6;
      box-shadow: 0 0 5px rgba(103, 61, 230, 0.3);
    }
  }
}
