html{
    box-sizing:border-box;
}
.vis{
    display: block;
}
@media screen {
    
}
.blogs{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    .blog-container{
       // max-width: 680px;
        margin:0 24px;
        min-width: 0;
        width: 100%;
        .header-blog{
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 0;
            color: #6b6b6b;
            line-height: 24px;           
        }
        .blog-body{
            //width: 100% ;
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            .blog-card{
               // max-width: 50%;
               // flex-basis: 50%;
                padding-right: 5px;
                padding-left: 5px;
                padding-bottom: 25px;
                flex-grow: 0;
                box-sizing: inherit;
                .card-body{
                    height: 100%;
                    display: block;                                          
                    .dv{
                        height: 100%;
                        .content{
                            box-sizing: content-box;
                            height: 100%;
                            display: block;
                            width: 100%;
                            .a{
                                display: grid;
                                position: relative;
                                grid-template-areas:
                                "image image image image image image image image image image image image"
                                "content content content content content content content content content content content content";
                                gap: 24px 0px;
                                grid-template-rows: auto 1fr;
                                grid-template-columns: repeat(12,1fr);
                                .b{
                                grid-area: image;
                                .imge{
                                 object-position: 50% 50%;
                                 object-fit: cover;
                                 aspect-ratio: 2/1;
                                 border-radius: 2px;
                                 background-color: #F9F9F9;
                                 width: 100% !important;
                                     }
                                 }
                                .c{
                                display: flex;
                                 grid-area: content;
                                 flex-direction: column;
                                justify-content: center;
                                 .d{
                                     display: flex;
                                     flex-grow: 1;
                                     flex-direction: column;
                                     width: 100%;
                                     /* box-sizing: inherit; */
                                    .author{
                                         display: flex;
                                         align-items: center;
                                        /*  box-sizing: inherit; */
                                        margin-bottom: 16px;
                                        .i{
                                         display: block;
                                         margin-right: 8px;
                                         .j{
                                          width: 20px;
                                          height: 20px;
                                          box-sizing: border-box;
                                          border-radius: 50%;
                                            }
                                        .name{
                                         margin: 0;
                                         padding: 0;
                                         word-break: break-all;
                                         display: -webkit-box;
                                         -webkit-box-orient: vertical;
                                         -webkit-line-clamp: 1;
                                         text-overflow: ellipsis;
                                         max-height: 20px;
                                         overflow: hidden;
                                         font-size: 13px;
                                         color: #242424;
                                         line-height: 20px;
                                         font-weight: 400;
                                         margin: 0;
                                         }
                                     }
                                 }
                                .desc{
                                  padding-bottom: 12px;
                                  flex: 1 0 auto;
                                  word-break: break-word;
                                  display: block;
                                 .title{
                                    max-height: 48px;
                                    letter-spacing: 0;
                                    line-height: 24px;
                                    font-size: 20px;
                                    font-weight: 700 !important;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    margin: 0;
                                    }
                                 .sub-head{
                                    max-height: 40px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    font-size: 16px;
                                    color: #6b6b6b;
                                    line-height: 20px !important;
                                    font-weight: 400 !important;
                                    margin: 0;
                                     }
                                  }
                             .footer{
                               font-size: 13px;
                               line-height: 20px;
                               color: #6b6b6b;
                               font-weight: 400;
                               display: flex;
                              .l{
                                display: flex;
                                height: 48px !important;
                                flex: 1 0 auto;
                                .heart{                    
                                  margin-right: 15px;                 
                                    }
                                }
                              .m{
                                 display: flex;
                                 justify-content: flex-end;
                                 align-items: center;
                                 flex: 0 0 0px;
                                 position: relative;
                                 .drop{
                                    position: absolute;
                                    top: -10px;
                                     right: -10px;
                                    }
                                }
                             }
                         }
                     }                                    
                   }
              }
          }
        }        
      }
    }
  }
}
