.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  background-color: rgb(249 250 251);
  border-top: none !important;
}
.modal-content {
  border: none !important;
  border-radius: none !important;
  outline: none !important;
}
.delete-tutorial-modal {
  .delete-modal-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    h3 {
      margin-left: 10px;
      font-weight: 600;
      color: rgb(17 24 39);
    }
    .icon-d {
      background-color: rgb(254 226 226);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      .tri-icon {
        width: 24px;
        height: 24px;
        fill: rgb(220 38 38);
      }
    }
    .icon-s{
      background-color: rgb(210, 243, 207);
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      .confirm-icon {
        width: 24px;
        height: 24px;
        fill: rgb(93, 226, 4);
      }
    }
  }
  .modal-body {
    color: rgb(107 114 128);
    font-size: 14px;

    p {
      margin-left: 50px;
    }
  }
}

.icon-d {
  background-color: rgb(254 226 226);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  .tri-icon {
    width: 24px;
    height: 24px;
    fill: rgb(220 38 38);
  }
}
.icon-s{
  background-color: rgb(210, 243, 207);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  .confirm-icon {
    width: 24px;
    height: 24px;
    fill: rgb(93, 226, 4);
  }
}