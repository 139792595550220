$border-color: #a8a6a682;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.creator-dashboard {
  height: 100vh;
  background: #f5f6f7;
}
// Creator header

.creator-studio-header {
  margin-top: 8vh !important;
}

.header {
  background: #fff;
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  border-bottom: 1px solid rgb(190, 184, 184);

  .header-logo {
    padding-left: 15px;
    img {
      width: 180px;
      height: 55px;
    }
  }
  .header-menu {
    width: 100%;
    padding: 16px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-input {
      label {
        width: 240px;
        height: 40px;
        padding-left: 16px;
        background-color: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      input {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      input:focus {
        outline: none;
      }
    }
    .information {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 16px;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
// creator side nav
.creator-side-nav {
  height: 90vh;
  overflow-y: auto;
  scrollbar-width: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  .creator-side-bar {
    padding: 0;
    margin: 0;
    .side-nav-dashboard {
      padding: 10px;
      p {
        font-size: 12px;
        color: #959fa3;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 10px 24px;
      }
      .nav-profile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
    }
    .side-nav-categories {
      margin: 10px;
      p {
        font-size: 12px;
        color: #959fa3;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 10px 24px;
      }
    }
    ul {
      padding-left: 0;
      li {
        list-style: none;
        a {
          width: 100%;
          padding: 10px 24px;
          display: flex;
          align-items: center;
          gap: 12px;
          color: #626c70;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        a:hover {
          background-color: #c3c3c324;
        }
        .active-tab {
          color: #191b1c;
          background: #f0f6ff;
          box-shadow: 3px 0px 0px 0px #0e5fd9 inset;
        }
        .logout-btn,
        .logout-btn:hover {
          background-color: rgb(236, 93, 71);
          color: white;
          margin: 10px 0;
          border-radius: 5px;
        }
      }
    }
  }
}
// Speaker section
.nav-speaker-header {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 5px #bebebe;
  .add-speaker-btn {
    font-weight: 600;
    padding: 5px 15px;
    background-color: #673de6;
    color: #fff;
    border: 1px solid #673de6;
    border-radius: 5px;
    &:hover {
      background-color: transparent;
      color: #673de6;
    }
  }
}
.nav-speaker-icon {
  margin: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
  .active-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #673de6;
  }
  .icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #808080;
  }
}
.custom-card {
  .nav-speaker-card {
    padding: 20px;
    box-shadow: 0 0 5px #808080;
    border-radius: 10px;
    .icon {
      cursor: pointer;
      margin-right: 5px;
    }
  }
}
// Nav Sponsor Section
.nav-sponsor-header {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 5px 5px #bebebe;
  .add-sponsor-btn {
    font-weight: 600;
    padding: 5px 15px;
    background-color: #673de6;
    color: #fff;
    border: 1px solid #673de6;
    border-radius: 5px;
    &:hover {
      background-color: transparent;
      color: #673de6;
    }
  }
}
.nav-sponsor-icon {
  margin: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
  .active-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #673de6;
  }
  .icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #808080;
  }
}
.custom-card {
  .nav-sponsor-card {
    padding: 20px;
    box-shadow: 0 0 5px #808080;
    border-radius: 10px;
    .icon {
      cursor: pointer;
      margin-right: 5px;
    }
  }
}
