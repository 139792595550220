@import "_variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.quiz-bg {
  background-color: $background-color;
}
.review-report {
  background-color: $background-color;
  font-family: "Montserrat";
  img {
    border: 0.5px solid rgb(236, 234, 234);
    border-radius: 100px;
    object-fit: contain;
  }
  .report-header {
    .send-email-btn {
      background-color: $lightcolor;
      border: 2px solid $sidebarlinkcolor;
      border-radius: 5px;
      padding: 5px 10px;
      color: $sidebarlinkcolor;
    }
    i {
      color: $sidebarlinkcolor;
    }
    .shortlisted-tag {
      font-size: 12px;
      color: rgb(63, 212, 63);
      font-weight: 600;
      border: 1px solid $border-color;
      background-color: rgba(202, 245, 202, 0.5);
    }
  }

  .report-description {
    background-color: $lightcolor;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 2px 2px 4px $border-color;
    .tag-desc {
      color: lightgrey;
      font-size: 12px;
    }
    .bi-check-circle {
      color: rgb(63, 212, 63);
    }
  }

  .time-analysis,
  .skill-performance,
  .key-observations,
  .report-comment {
    background-color: $lightcolor;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 2px 2px 4px $border-color;
    .tag-desc {
      color: lightgrey;
      font-size: 12px;
    }
    .observation-card {
      border: 1px solid $border-color;
      .heading-part {
        background-color: rgba(249, 249, 249, 255);
        span {
          color: rgb(59, 58, 58);
          font-size: 16px;
          font-weight: 600;
        }
      }
      .desc-part {
        color: $grey;
      }
    }
    .post-button {
      background-color: $button-color;
      border: 1px solid $border-color;
      border-radius: 6px;
      padding: 4px 16px;
      color: $lightcolor;
    }
    .cancel-button {
      font-size: 16px;
    }
    textarea {
      width: 100%;
      background-color: rgba(242, 250, 255, 255);
      border: 1px solid rgba(242, 250, 255, 255);
      font-size: 14px;
    }
  }

  .about-candidate {
    background-color: $lightcolor;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 2px 2px 4px $border-color;
    .heading-para {
      font-size: 14px;
      font-weight: 600;
    }
    .candidate-details {
      .about-heading {
        color: rgb(92, 91, 91);
      }
      span {
        font-size: 14px;
      }
    }
  }

  .detailed-submission {
    background-color: $lightcolor;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 2px 2px 4px $border-color;
    .heading-para {
      font-size: 14px;
      font-weight: 600;
    }
    .detail-table-header {
      background-color: $background-color;
    }
    .questions-detail-toggle {
      border: 1px solid $border-color;
      .bi-chevron-right,
      .bi-chevron-down {
        color: $sidebarlinkcolor;
      }
      .correct-field {
        background-color: rgba(63, 212, 63, 0.6);
        padding: 3px 6px;
        font-size: 12px;
        margin-left: 3px;
      }
      .bi-x-circle {
        color: red;
      }
      .bi-check-circle {
        color: rgba(63, 212, 63, 0.6);
      }
    }
  }
}
