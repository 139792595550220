/* styles.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.header-class {
    background-color: #F9F9F9;
    color: #99a1b7;
    text-align: center;
    font-family: "Inter",sans-serif;

  }
  
  .row-class {
    text-align: center;
  }
  
  .table .thead-dark th {
    font-family: "Inter",sans-serif;
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    border-color: #454d55;
  }
 .table td {
    text-align: center ;
    vertical-align: middle !important;
  }
  .symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: .475rem;
}
.me-3 {
  margin-right: .75rem !important;
}

.Administrator{
  background-color: #E9F3FF;
  color:#1b84ff
}

.Developer{
  background-color: #FFEEF3;
  color: #F8285A;
}
.Trial{
  background-color: #FFF8DD;
  color: #F6C000;
}
.Analyst{
  background-color: #DFFFEA;
  color:#17C653;
}
.Support{
  background-color: #F8F5FF;
  color: #7239EA;
}