@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
$bg-color:#FCFCFC;
$card-bg-color:white;
$border-color:#F1F1F4;
$font-fam:"Inter" , sans-serif;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html{
    min-height: 100vh !important;
    background-color: $bg-color !important;
  }
.role-list{
    background-color: $bg-color;
    padding-top: 10px;
    font-family: $font-fam;
    font-weight: 600;
    .padding-x{
        padding-left:210px;
        padding-right:210px;
    }
    .header-role{
        
        font-style:normal ;
        color:#071437;
        font-size: 18px;
        line-height: 21px;
        
    }
    .role-path{
      color:#99A1B7;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
    }
    .buttons{
        font-size: 12px;
        line-height: 19px;
        .filterr{
            position:relative;
        .filter-btn{
            color: #252f4a;
            background-color: #DBDFE9;
            
            margin-right:8px;}
        .filter-popup{
                position:absolute;
                background-color: $card-bg-color;               
                width: 300px;
                top: 40px;
                left:-240px;
                box-shadow:  0px 3px 4px 0px rgba(0, 0, 0, 0.03);
                border-color: $border-color;
                border: 1px solid $border-color;
                z-index: 100;
                .filter-card{
                    font-family: $font-fam;
                    .filter-header{
                        font-weight: 600 !important;
                        font-size: 15px !important;
                        line-height: 22px !important;
                        color: rgb(7, 20, 55) !important;

                    }
                }
            }
        }
        .create-btn{
            color:white;
        }
    }


 .role-card{
    background-color:$card-bg-color!important;
    font-family: $font-fam;
    font-weight: 600;
    font-style: normal;
    box-shadow:  0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border-color: $border-color;
     border: 1px solid $border-color;
     position: relative;
     .dot{
        position: absolute;
        top:21px;
        right:0px;
     }
    .card1-header{       
        font-size: 20px;       
        line-height:23px;
        color: #071437;
        padding:2rem 2.25rem;
    }
    .del-btn{
        background-color: white;
        border: none ;
        cursor: pointer;
    }
    .card1-body{
        padding:2rem 2.25rem;
        padding-top: .25rem !important;
        font-size: 13px;
        line-height: 20px;
        .sub-header{
            color:#78829D ;
            padding-bottom: 1.25rem !important;
            
        }
        .feature{
            display: flex;
            color: #78829D;
            align-items: center !important;
            padding-top: .5rem !important;
            padding-bottom: .5rem !important;
            font-weight: 400 !important;
            padding-left:0 !important;
            .bullet{
                display: inline;
                border-radius: 6px;
                width: 8px;
                height: 4px;
                flex-shrink: 0;
                margin-right: .75rem !important;
            }
        }
    }
    .card-footer{
        padding: 2rem 2.25rem;
        border-top: 0 !important;
        padding-top: 0 !important;
        background-color: $card-bg-color !important;
    }
}


@media screen and (max-width:1471px) {
     .padding-x{
        padding-left:70px;
        padding-right:70px;
    }
}

@media screen and (max-width:1200px) {
   .padding-x{
        padding-left:30px;
        padding-right:30px;
    }
  }

.user-card{
    background-color: $card-bg-color !important;
    font-family:$font-fam;
    font-weight: 600;
    font-style: normal;
    box-shadow:  0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border-color: $border-color;
     border: 1px solid $border-color;
     max-height: 700px;
     
    .card1-header{       
        font-size: 20px;       
        line-height:23px;
        color: #071437;
        padding:2rem 2.25rem;
    }
    .form-control-solid{
        background-color: #F9F9F9;
        border-color: #F9F9F9;
        color: #4B5675;
        transition: color .2s ease;
       
    }
    .overflow-scroll{
        max-height: 500px;
        overflow-y: auto;
        position: relative;
        scrollbar-width: thin;
        scrollbar-color: transparent;
    }
    }

}
.modal1-header{
    font-family: $font-fam;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    color: #071437;
}
.modal-label{
    font-family: $font-fam;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    color: #252f4a;
}
.scroll-y{
    overflow-y: scroll;
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: transparent;
}
.table{
    font-family: $font-fam;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #252f4a;
    .span{
        color: #99a1b7 !important;
    }

}
.modal-height{
    max-height: 550px;
}
.modal-content{
    width: auto !important;
}
.form-check{
    font-family: $font-fam;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #99a1b7 !important;
}
.table td{
    font-family: $font-fam;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: #252f4a;

}

.font-f{
    font-family: $font-fam;
    font-weight: 400;
    color:  #252f4a; 
}
.table-overflow{
    overflow-y: auto;
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: transparent;
}